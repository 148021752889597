.agenda-hd {
  font-family: "Tesla Slab Bold 2";
  font-style: normal;
  line-height: 22px;
}

.agenda-p {
  font-family: "Galaxie Copernicus Medium";
  line-height: 162%;
  letter-spacing: -0.015em;
}

.agenda-h2 {
  font-family: "Tesla Slab Bold 2";
  line-height: 162%;
  letter-spacing: -0.015em;
  color: #ec0d0d;
  font-size: 32px;
}

.agenda-h3 {
  font-family: "Galaxie Copernicus Extra Bold";
  line-height: 162%;
  letter-spacing: -0.015em;
  color: #002578;
}

.bt-blue2 {
  font-family: "Galaxie Polaris";
  color: #0808ff;
  box-shadow: inset 0 0 0 2px #0808ff;
  border-radius: 0;
}

.bt-blue {
  font-family: "Galaxie Polaris";
  background: #0808ff;
  border-radius: 0;
}

.pma-hd > h1 {
  font-style: normal;
  letter-spacing: -0.01em;
  font-size: 40px;
  font-family: Galaxie Copernicus Bold;
}

.suc-txt li {
  font-family: "Galaxie Polaris Medium";
  font-size: 0.813rem;
  line-height: 162%;

}

.suc-li{
  font-family: "Galaxie Polaris";
    }

#side-nav .usa-sidenav a{
  padding-bottom: 1rem;
}

#open-left-side-menu{
 color:$theme-color-white;
}