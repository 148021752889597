.pgov-basic #main-content h3.filter-title{
  color: #000;
}
.quick-reference-border {
  border-top: solid 4px #8889db;
  span.usa-tag{
    background-color: #e5e4fa;
    color: #3d4076;
  }
}
.legislation-border {
  border-top: solid 4px #f2938c;
  span.usa-tag{
    background-color: #f8e1de;
    color: #6f3331;
  }
}
.guidance-border {
  border-top: solid 4px #97d4ea;
  span.usa-tag{
    background-color: #c3ebfa;
    color: #074b69;
  }
}
.report-border {
  border-top: solid 4px #F79520;
  span.usa-tag{
    background-color: #F79520;
    color: #6f3331;
  }
}
.playbook-border {
  border-top: solid 4px #92d9bb;
  span.usa-tag{
    background-color: #c7efe2;
    color: #193324;
  }
}
#secondary-navigation {
  .pic-filters {
    li {
      display: inline-block;
    }
    a {
      display: inline-block;
      padding: 0.5em 1em;
      font-size: 0.875em;
      border: solid 1px gray;
      color: #333;
      text-decoration: none;
      background: white;
      margin: 0.2em 0;

      &.is-checked {
        background: #595959;
        color: white;
        &:hover {
          color: white;
        }
      }

      &:hover {
        outline: 2px solid grey;
        color: #333;
      }
    }
  }
}