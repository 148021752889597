.usa-hero {

  }


.faq-item {
  margin-bottom: 10px !important;
}

.usa-banner__button::after {
  background: url("/assets/img/usa-icons-bg/expand_more--white.svg") no-repeat center / 1rem 1rem;
}

.usa-banner__button::after:hover {
  background-color: transparent;
}

.usa-banner__button[aria-expanded="true"]::after {
  background: url("/assets/img/usa-icons-bg/expand_less--white.svg") no-repeat center / 1rem 1rem;
}

#president-agenda {
  .grid-row {
    width: 100%;
  }
}

.homepage-banner {
  height: 210px;
}

.banner-title {
  padding-top: 20px;
}

.site-card .card-img {
    width: 100%;
    height: 15rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    overflow: hidden;
}

.site-card {
    color: #3d4551;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.18);
    background-color: white;
}

.site-card .card-body {
    flex: 1 1 0%;
    padding: 1rem;
    color: #3d4551;
}

.site-card .card-flag {
    font-size: 1.34rem;
    color: #2672de;
    font-weight: bold;
    font-style: normal;
    margin: .5rem 0;
}

.site-card .card-footer {
    padding: 1rem;
    padding-top: 0;
    text-align: right;
}

.site-card .card-footer a, .site-card .card-footer a:visited, .site-card .card-footer a:active, .site-card .card-footer .text-link {
    position: relative;
    color: #2672de;
    font-weight: normal;
    padding-left: 15px;
    margin-right: 10px;
}

.site-card .card-affordance {
    margin: 0;
    text-decoration: underline;
}


.fm-tagline {
    font-style: normal;
    font-size: 22px;
    line-height: 135%;
    color: #205493;
}

.card-btn-ft {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1px solid #DFE1E2;
    float: left;
    border-radius: 4px;
    padding: 6px 12px;
}

.bl-head {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 300%;
  text-align: center;
  color: #000;
}

.footer-content {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.usa-footer__secondary-section {
  padding-top: 3rem;
}

// vaccine widget
.vaccine-card {
  height: 230px;
  width: 280px;
  background-color: #ede8e7;
  padding: 1.5rem 4rem;
  margin-right: 5px;
}

.vaccine-card-content {
  color: #2d57b7;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  font-family: ui-sans-serif;
  letter-spacing: 2px;
}

.vaccine-container {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 745px) {

  .vaccine-container {
    margin-top: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .vaccine-card {
    margin-right: unset;
  }
}

.fefashion {
    display: inline-block;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}