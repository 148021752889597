.cx-hero {
	background-image: url(/assets/images/cx/cx-header.jpg);
	background-position: bottom;
	min-height: 440px;

	h1 {
		line-height: 1.125em;
	}
}

.cx-hero.projects-page {
	background-image: url(/assets/images/cx/cx-header-illustration-02.png);
}

// Custom Touchpoints tab styling
#fba-button.fixed-tab-button.usa-button {
	margin-bottom: 0;
	font-size: 16px;
}

.touchpoints-form-wrapper
.usa-banner__header-text {
	color: inherit;
}

.usa-accordion__button.text-white[aria-expanded="false"] {
	background-image: url(/assets/images/icons/add_white.svg), linear-gradient(transparent, transparent);
}

.usa-accordion__button.text-white[aria-expanded="true"] {
	background-image: url(/assets/images/icons/remove_white.svg), linear-gradient(transparent, transparent);
}

.bg-light-gray {
	background-color: #F2F2F2;
}

.pgov.usa-card-group {
	.usa-card__container {
		margin-top: 1rem;
		margin-bottom: 0.75rem;
		border: none;
		border-radius: 0;
		border-top: 1px solid;

		.usa-card__header {
			padding-left: 0;
			padding-right: 0;
		}
		.usa-card__body {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
