.pma-container {
  display: flex;
  position: relative;

  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column-reverse;
  }

  .pma-image {
    @media screen and (max-width: $tablet) {
      margin-bottom: 2em;
    }
  }

  .pma-column {
    @media screen and (max-width: $tablet) {
      flex-direction: column-reverse;
    }
  }

  .PMA {
    @media screen and (max-width: $iphone) {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: -60px;
      max-width: 110%;
    }
  }

  .hero-message {
    text-align: center;
    @media screen and (min-width: $desktop) {
      text-align: right;
    }
  }

  .pma-button {
    text-align: center;
    @media screen and (min-width: $desktop) {
      text-align: left;
    }
  }

  .prio-label1 {
    font-family: "Galaxie Polaris Medium";
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    color: #0808ff;
  }

  .usa-sidenav {
    @media screen and (max-width: $iphone) {
      margin: 0;
    }
  }
  .usa-sidenav a:not(.usa-button):hover {
    background: none;
  }

  .usa-sidenav .usa-current::after {
    display: none;
  }

  .usa-sidenav__item {
    border-top: 0;
  }

  .usa-sidenav a:not(.usa-button) {
    padding: 0;
    padding-bottom: 1rem;
  }

  .usa-sidenav .usa-current {
    font-weight: 500;
  }

  .pma-landing-img {
    width: 100%;
  }

  .pma-letter-content {
    font-family: "Galaxie Copernicus Medium";
    font-style: italic;
    font-size: 14px;
  }

  .usa-link .arrow-right {
    visibility: hidden;
  }
  .usa-link.active .arrow-right,
  .usa-link:hover .arrow-right {
    visibility: visible;
  }

  .pma-landing-title {
    font-family: "Galaxie Copernicus Medium";
  }

  .read-more {
    display: block;
    font-size: 12px;
    font-family: "Galaxie Polaris";
    color: #002578;
    margin-top: 24px;
    border-bottom: 1px solid #ec0d0d;
    width: fit-content;
    text-decoration: none;
  }

  .usa-button {
    font-weight: 600;
    font-size: 14px;
    font-family: "Galaxie Polaris";
  }
}

.pma-link {
  margin-top: 10px;
}

.accessible-version-link {
  padding: 12px 16px;
  font-family: "Galaxie Polaris";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color: black;
}

.pma-img-bottom-border {
  border-bottom: 20px solid #0808ff;
}

.pma-vision-img {
  @media screen and (min-width: $tablet) {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
}

#side-nav {
  @media screen and (max-width: $tablet) {
    position: fixed !important;
    z-index: 1000;
    width: 320px;
    overflow-y: auto;
    border-right: 2px solid black;
    background: #e9eaed;
    overflow-x: hidden;
    padding-top: 15px;
    transition: 0.5s;
    height: 100%;
    margin-left: -8px;
    padding-left: 16px;
  }
}

#left-side-menu-buttons {
  @media screen and (max-width: $tablet) {
    background-color: #27324b;
    border-top: 1px solid #f6f6f6;
    border-top: 1px solid var(--button-leftside-outer-border);
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    margin-left: -15px;
  }
}

.council-signatures {
  img {
    max-width: 20%;
    margin-bottom: -15px;
    margin-top: 20px;
  }
}

.pma-quote {
  margin: 3rem 0;

  hr {
    width: unset;
  }

  p {
    font-size: 30px;
  }

  .quote-name {
    font-size: 23px;
    color: #0808ff;
    font-weight: bolder;
    text-align: right;
    font-family: "Galaxie Copernicus bold";
  }

  .font-red-bold {
    color: #ec0d0d;
    font-family: "Galaxie Copernicus bold";
  }
}
