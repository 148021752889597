.text-tesla-slab-bold2 {
  font-family: "Tesla Slab Bold 2", sans-serif;
}

.text-galaxie-polaris-medium {
  font-family: "Galaxie Polaris Medium";
}

.galaxie-bold {
  font-family: "Galaxie Copernicus Bold";
}

@media screen and (min-width: 640px) {
  .tablet\:width-1\/3 {
    width: 33%;
  }

  .tablet\:float-left {
    float: left;
  }
}

@media screen and (min-width: 640px) {
  .paginate-link {
    display: initial;
  }

  .paginate-button {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .paginate-link {
    display: none;
  }

  .paginate-button {
    display: block;
  }
}

body {
  color: #000000;
}

p {
  margin-bottom: 1em;
  margin-top: 1em;
}

.primary-font--medium {
  font-family: "Galaxie Copernicus Medium";
}

.primary-font--regular {
  font-family: "Galaxie Copernicus";
}

.font-tesla-slab-reg {
  font-family: "Tesla Slab Regular 2";
}

.font-galaxy-polaris {
  font-family: "Galaxie Polaris";

  &.medium {
    font-family: "Galaxie Polaris Medium";

    &.italic {
      font-family: "Galaxie Polaris Medium Italic";
    }
  }
}

.font-size-14 {
  font-size: 0.875em;
}

.font-size-16 {
  font-size: 1em;
}

.text-pgov-color-secondary {
  color: $theme-color-secondary;
}

figcaption {
  font-family: "Galaxie Copernicus Medium";
  line-height: 1.5;
  margin-bottom: 1em;
  margin-top: 1em;
}

.logo-img {
  height: 45px;

  @media screen and (min-width: 951px) {
    vertical-align: bottom;
  }
}

.usa-banner__flag {
  background-color: #323a45;
}

.usa-banner__header-text {
  color: white;
}

.usa-banner__button {
  color: white;
}

.usa-footer__logo-img {
  max-width: 9rem;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
}

// Then style the iframe to fit in the container div with full height and width
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media all and (min-width: 40em) {
  .usa-hero__callout {
    max-width: 22rem;
  }

  .usa-section.grid-container.margin-top-neg-ft {
    padding-bottom: 1rem;
  }
}

@media all and (max-width: 380px) {
  .margin-top-neg-1 {
    margin-bottom: -4rem;
  }

  .video-responsive {
    overflow: visible;
  }
}

@media all and (max-width: 638px) {
  .margin-top-neg-1 {
    margin-top: 1.5rem;
  }

  .logotxt-font {
    font-size: 1rem;
    //     margin-left:  0.7rem;
  }
}

@media all and (max-width: 812px) {
  .video-responsive {
    margin-top: -42px;
    padding-bottom: 64.25%;
  }
}

@media all and (max-width: 400px) {
  .video-responsive {
    padding-bottom: 69.25%;
  }
}

.council-background {
  background-image: url("images/hero-callout-background-1.png");
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pgov-bg-img {
  background-image: url(/assets/images/banners/pgov-hero-img.jpg);
}

.usa-navbar {
  overflow: hidden !important;
}

.project-item__outer {
  margin-bottom: 2rem;
}

.project-item__outer.hide {
  display: none;
}

.usa-social-link--linkedin {
  background-image: url(/assets/images/icons/linkedin.svg),
    linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}

//p.gov width

@media (min-width: 64em) {
  .usa-header--extended .usa-navbar,
  .usa-banner__inner,
  .grid-container,
  .usa-footer__primary-section > .grid-container,
  .usa-footer__secondary-section > .grid-container {
    max-width: 82.28rem;
  }

  .usa-footer__primary-section .grid-row.grid-gap-4 {
    justify-content: space-between;
  }

  .usa-header--extended .usa-nav__inner {
    max-width: 89.28rem;
  }
}

@media all and (max-width: 1254px) {
  .usa-header--extended .usa-navbar,
  .usa-banner__inner,
  .usa-header--extended .usa-nav__inner,
  .grid-container,
  .usa-footer__primary-section > .grid-container,
  .usa-footer__secondary-section > .grid-container {
    // max-width: 74rem;
  }
}

@media all and (max-width: 1190px) {
  .usa-header--extended .usa-navbar,
  .usa-banner__inner,
  .usa-header--extended .usa-nav__inner,
  .grid-container,
  .usa-footer__primary-section > .grid-container,
  .usa-footer__secondary-section > .grid-container {
    max-width: 71rem;
  }
}

@media all and (max-width: 1124px) {
  .usa-header--extended .usa-navbar,
  .usa-banner__inner,
  .usa-header--extended .usa-nav__inner,
  .grid-container,
  .usa-footer__primary-section > .grid-container,
  .usa-footer__secondary-section > .grid-container {
    max-width: 71rem;
  }

  .usa-footer__logo-heading {
    font-size: 1.2rem;
  }
}

@media (min-width: 40em) {
  .usa-card--flag .usa-card__media {
    width: 25rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__header,
  .usa-card--flag.usa-card--media-right .usa-card__body,
  .usa-card--flag.usa-card--media-right .usa-card__footer {
    margin-right: 25rem;
    margin-top: 6rem;
  }
}

.card__footer {
  padding: 1rem;
  padding-top: 0;
  margin-left: 17rem;
}

@media all and (max-width: 1023px) {
  .ft-responsive {
    width: 100%;
  }

  .footer-section {
    width: 100%;
  }
}

.sm-link {
  font-style: italic;
  font-weight: normal;
  font-size: 32px;
  line-height: 135%;
  color: #205493;
}

.card-tag {
  color: #5b616b;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #323a45;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 0.7em;
  text-align: center;
}

.next-icon {
  color: violet;
  fill: white;
}

.card-tag a {
  text-decoration: none;
  background-color: #e31c3d;
  color: #ffffff;
  padding: 10px;
  border-radius: 25px;
}

.card-link-tagline {
  text-decoration: none;
  color: #205493;
  display: block;
}

.hr-line {
  border-width: 0;
  background-color: #e31c3d;
  width: 100%;
  height: 1px;
}

.blog-img {
  margin-bottom: 3%;
}

.text {
  color: white;
  position: absolute;
  padding: 8px;
}

.description-2020 {
  font-size: 10px;
  color: white;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

// slide down
.container-GoG {
  position: relative;
  display: block;
  width: 100%;
  // border-radius: 10px;
  cursor: pointer;
  margin-bottom: 6px;
}

.container-GoG-pres {
  position: relative;
  width: 100%;
  display: block;
  border-radius: 10px;
  height: 150px;
  cursor: pointer;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(8, 8, 255, 0.8);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.container-GoG:hover .overlay {
  bottom: 0;
  height: 100%;
}

.container-GoG:focus .overlay {
  bottom: 0;
  height: 100%;
}

.text-ag-cou {
  position: absolute;
}

.text {
  color: white;
  position: absolute;
  padding: 8px;
}

.card-tag-winner {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
}

#main-content {
  overflow-x: hidden;
}

.twitter {
  color: white;
  background-color: #1da1f2;
  width: 200px;
  text-align: center;
  font-size: 1.4rem;
  float: right;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 8px;
  margin: 3.5px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .twitter {
    float: none;
  }
}

// The Modal (background)
.GoG-modal {
  display: none;
  // Hidden by default
  position: fixed;
  // Stay in place
  z-index: 1;
  // Sit on top
  padding-top: 50px;
  // Location of the box
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  // Full width
  height: 100%;
  // Full height
  overflow: auto;
  // Enable scroll if needed
  background-color: black;
  // Fallback color
  background-color: rgba(0, 0, 0, 0.3);
  // Black w/ opacity
}

// Modal Content
.modal-content-GoG {
  background-color: #ffffff;
  color: #212121;
  margin: auto;
  padding: 40px;
  border: 5px solid #d6d7d9;
  width: 40%;
  text-align: left;
  min-width: 400px;
}

.modal-content-GoG h2 {
  font-size: 2em;
}

@media screen and (min-width: 1201px) {
  .margin-bio {
    margin-left: 45px;
  }

  .show-btn {
    margin-left: -9rem;
    margin-top: -5rem;
  }
}

.blog-button {
  background-color: #f1f1f1;
  border-radius: 20px;
  border: 1.5px solid #205493;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  color: #0071bc;
  margin-bottom: 10px;
}

.card-filter {
  height: 100% !important;
}

.ft-dot {
  height: 6px;
  width: 6px;
  background-color: #e41d3d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  vertical-align: 2px;
}

.ft-font {
  color: #162e51;
  font-size: 1.9rem;
}

.padding-x-2-fl {
  padding-left: 0.7rem;
  padding-right: 1rem;
}

.show-more {
  cursor: pointer;
  float: right;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 0.7em;
  text-decoration: none;
  background-color: $theme-color-red;
  color: $theme-color-white;
  padding: 10px;
  border-radius: 25px;

  &:hover {
    color: $theme-color-white;
  }
}

.padding-x-2-pps {
  padding-right: 5rem;
}

.usa-card__container:hover {
  box-shadow: none;
}

.usa-social-link--mail {
  background-image: url("../img/usa-icons/mail.svg");
  background-repeat: no-repeat;
}

.usa-social-link--linkedin {
  background-image: url("../img/usa-icons/svg/linkedin.svg");
  background-repeat: no-repeat;
}

.usa-social-link {
  background-color: unset;
}

.iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  border-radius: 15px;
}

.usa-prose > p {
  max-width: 96ex;
}

.about-page__prose.usa-prose > p,
.about-page__prose.usa-prose > ul {
  max-width: 68ex;
}

// about page styles
#about-sidenav {
  .usa-sidenav {
    border-bottom: 1px solid #a9aeb1;
    // USWDS token: BG Base Lighter
  }

  .usa-sidenav li {
    font-family: "Tesla Slab Regular 2", sans-serif;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .usa-sidenav__item {
    border-top: 1px solid #a9aeb1;
    // USWDS token: BG Base Lighter
  }

  .usa-sidenav .usa-current {
    position: relative;
    color: $theme-color-secondary;
    font-weight: 700;
  }

  .usa-sidenav .usa-current::after {
    background-color: $theme-color-lt-blue;
    border-radius: 0;
  }

  .usa-sidenav a:not(.usa-button):not(.usa-current):hover {
    color: $theme-color-primary;
  }
}

// end

.usa-list li,
.usa-prose > ul li,
.usa-prose > ol li,
.usa-collection__item,
.usa-prose .usa-collection__item {
  max-width: 95ex;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.fade_line {
  margin: 25px 0;
  height: 2px;
  background: black;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(white),
    to(white),
    color-stop(50%, #005ea2)
  );
}

.font-sans-lg {
  font-size: 1.4639705882rem;
}

@media all and (min-width: 30em) {
  .mobile-lg\:font-sans-lg {
    font-size: 1.4639705882rem;
  }
}

@media all and (min-width: 40em) {
  .tablet\:font-sans-lg {
    font-size: 1.4639705882rem;
  }
}

@media all and (min-width: 64em) {
  .desktop\:font-sans-lg {
    font-size: 1.4639705882rem;
  }
}

.font-sans-xl {
  font-size: 2.1294117647rem;
}

@media all and (min-width: 30em) {
  .mobile-lg\:font-sans-xl {
    font-size: 2.1294117647rem;
  }
}

@media all and (min-width: 40em) {
  .tablet\:font-sans-xl {
    font-size: 2.1294117647rem;
  }
}

@media all and (min-width: 64em) {
  .desktop\:font-sans-xl {
    font-size: 2.1294117647rem;
  }
}

.font-alt-xl {
  font-size: 1.9514824798rem;
}

@media all and (min-width: 30em) {
  .mobile-lg\:font-alt-xl {
    font-size: 1.9514824798rem;
  }
}

@media all and (min-width: 40em) {
  .tablet\:font-alt-xl {
    font-size: 1.9514824798rem;
  }
}

@media all and (min-width: 64em) {
  .desktop\:font-alt-xl {
    font-size: 1.9514824798rem;
  }
}

.font-sans-xl {
  font-size: 2.1294117647rem;
}

@media all and (min-width: 30em) {
  .mobile-lg\:font-sans-xl {
    font-size: 2.1294117647rem;
  }
}

@media all and (min-width: 40em) {
  .tablet\:font-sans-xl {
    font-size: 2.1294117647rem;
  }
}

@media all and (min-width: 64em) {
  .desktop\:font-sans-xl {
    font-size: 2.1294117647rem;
  }
}

// blog social media icons

#share-bar {
  font-size: 20px;
}

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

.share-button {
  margin: 0px;
  margin-bottom: 0px;
  margin-right: 3px;
  padding: 3px 3px 3px 3px;
}

a {
  color: #2D63FF;
}

a:hover,
a:active {
  color: #002578;
}

.share-button {
  margin: 0px;
  margin-bottom: 0px;
  margin-right: 3px;
  padding: 3px 3px 3px 3px;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;

  li {
    display: inline-block;
    margin: 0.15em;
    position: relative;
    font-size: 1.2em;
  }

  i {
    color: #fff;
    position: absolute;
    top: 21px;
    left: 21px;
  }

  a {
    display: inline-block;

    &:before {
      content: " ";
      width: 60px;
      height: 60px;
      border-radius: 100%;
      display: block;
      background: linear-gradient(45deg);
    }

    &:hover:before {
      transform: scale(0);
    }

    &:hover i {
      background: -webkit-linear-gradient(45deg);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  a.social-square {
    &:before {
      background: linear-gradient(45deg);
      border-radius: 10%;
    }

    &:hover:before {
      transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -webkit-transform: scale(-180deg);
      border-radius: 100%;
    }

    &:hover i {
      color: #fff;
      transform: scale(1.6);
      -webkit-text-fill-color: #fff;
    }
  }
}

.usa-media-block__body {
  max-width: 39rem;

  & li {
    margin-bottom: 1em;
  }
}

hr {
  @media screen and (min-width: 685px) {
    width: 70%;
  }

  width: 85%;
  height: 3px;
  background: #005ea2;
  border-style: none;
}

.post-byline {
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
}

.bio-photo {
  float: left;
  width: 175px;
  height: 175px;
  object-fit: cover;
  margin-right: 24px;
  border-radius: 10px;
}

.font-alt-xl {
  font-size: 1.95148rem;
}

.seal-card {
  text-align: center;
}

.boarderBottom {
  border-bottom: 2px solid #e41d3d;
  text-align: center;
}

.dep-text {
  text-align: center;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 8px;
}

.dep-name-text {
  font-family: Arial, Helvetica, sans-serif;
}

.clearfix {
  height: 274px;
}

.clearfix-lg {
  height: 22rem;
}

.nameSeal {
  font-weight: bold;
}

.headerMember {
  font-size: 18px;
  font-weight: bold;
}

.boarderBottom-1 {
  border-bottom: 1px solid #e41d3d;
  text-align: center;
}

.name {
  font-size: 14px;
  font-weight: bold;
}

.title {
  font-size: 13px;
  line-height: 10px;
  font-weight: 500;
}

.usa-media-block__body > a {
  display: block;
}

.usa-section-ltblue-border {
  background-color: #205493;
  color: #ffffff;
  border-top: 1.6rem solid #205493;
}

.testimonial-blockquote {
  font-size: 1em;
  width: 100%;
  margin: 3.5rem auto;
  font-style: italic;
  color: #1c304a;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid #046b99;
  line-height: 1.4;
  position: relative;
  background: #f1f1f1;
}

.testimonial-blockquote p::before {
  content: "\201C";
  color: #046b99;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

.usa-prose > ul {
  line-height: 1.5;
}

.usa-alert--info:before {
  -webkit-mask: url("../img/usa-icons/info.svg") no-repeat center/2rem 2rem;
}

#backtotop {
  font-family: "Galaxie Copernicus Medium";
}

.que-font {
  font-family: "Galaxie Copernicus Bold Italic";
}

// homepage banner
.hm-banner-tx {
  font-family: "Galaxie Copernicus Bold Italic";
  color: $theme-color-white;
}

.pr-class a {
  font-size: 0.688rem;
  line-height: 100%;
  color: $theme-color-white;
}

.milestone-bl {
  background-color: $theme-color-white;
  color: $theme-color-base-ink;
  border: 1px solid #002578;
  line-height: 130%;
  cursor: default;

  &:hover {
    background: $theme-color-white;
    color: $theme-color-base-ink;
    border-bottom: 1px solid #002578;
  }
}

//pma progress accordion
.acc-progress .usa-accordion__button {
  background-color: $theme-color-primary;
  font-family: "Galaxie Polaris", sans-serif;
  color: $theme-color-white;
  text-transform: uppercase;
  line-height: 0.438rem;
  background-image: none;
  font-size: 0.77rem;
}

.custom-counter .usa-accordion__button {
  background-color: white;
  font-family: "Galaxie Polaris Medium";
  font-size: 13px;
  color: black;
  text-decoration: underline;
  text-transform: none;
  padding: 0;
}

.acc-progress .usa-accordion__button[aria-expanded="false"] {
  background-image: none;
  background-repeat: no-repeat;
  background-size: 1rem;
}

.pma-banner::after {
  background: url(/img/usa-icons/Vector.svg) no-repeat center/1rem 1rem;
  background-size: 0.938rem;
}

.pma-banner[aria-expanded="true"]::after {
  background: url(/img/usa-icons/Vector-open.svg) no-repeat center/1rem 1rem;
}

.pma-banner:after {
  position: absolute;
  display: inline-block;
  height: 0.5rem;
  width: 1rem;
  content: "";
  vertical-align: middle;
  margin-left: 11px;
}

.rt-top {
  font-family: "Tesla Slab Regular 2";
  font-size: 15px;
  line-height: 20px;
}

a.pma-logo {
  color: #fff;
}

// Custom Styles
@import "custom/pgov-responsive";
@import "custom/pagination";
@import "custom/breadcrumb";
@import "custom/image";
@import "custom/project-fellows";
@import "custom/list-fellows";
@import "custom/typography";
@import "custom/footer-tagline";
@import "custom/story";
@import "custom/filters";
@import "custom/homepage";
@import "custom/pgov-new-design";
@import "custom/pgov-blog";
@import "custom/pma-goals";
@import "custom/whldp";
@import "custom/main";
@import "custom/cx";
@import "custom/pma-learning-agenda";
@import "custom/basic";
@import "custom/agency";
@import "custom/pic-resources";

/* For the hover effect */
.color-change:hover path[fill='#fff'],
.color-change:hover path[fill='#ffffff'],
.color-change:hover path[fill='white'],
.color-change:hover circle[fill='#fff'],
.color-change:hover circle[fill='#ffffff'],
.color-change:hover circle[fill='white'],
.color-change:hover rect[fill='#fff'],
.color-change:hover rect[fill='#ffffff'],
.color-change:hover rect[fill='white'],
.color-change:hover polygon[fill='#fff'],
.color-change:hover polygon[fill='#ffffff'],
.color-change:hover polygon[fill='white'],
.color-change:hover ellipse[fill='#fff'],
.color-change:hover ellipse[fill='#ffffff'],
.color-change:hover ellipse[fill='white'],
.color-change:hover line[stroke='#fff'],
.color-change:hover line[stroke='#ffffff'],
.color-change:hover line[stroke='white'],
.color-change:hover polyline[fill='#fff'],
.color-change:hover polyline[fill='#ffffff'],
.color-change:hover polyline[fill='white'],
.color-change:hover text[fill='#fff'],
.color-change:hover text[fill='#ffffff'],
.color-change:hover text[fill='white'] {
    fill: #E52207 !important; /* Using !important to override any inline styles */
}

.color-change:hover [stroke='#fff'],
.color-change:hover [stroke='#ffffff'],
.color-change:hover [stroke='white'] {
    stroke: #E52207 !important;
}

/* Adding a fade transition */
.color-change path, 
.color-change circle, 
.color-change rect, 
.color-change polygon, 
.color-change ellipse, 
.color-change line, 
.color-change polyline, 
.color-change text {
    transition: fill 0.3s ease, stroke 0.3s ease;
}
