.pgov-basic {
  font-size: 1rem;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.62;
  #main-content {
    font-family: "Galaxie Copernicus Medium";
    .usa-prose {
      font-family: "Galaxie Copernicus Medium";
      font-size: 1rem;
      ul.usa-button-group {
        padding-left: 0;
      }
      .usa-icon-list{
        a{
          font-family: 'Tesla Slab Regular 2';
        }
      }
    }
    h1 {
      font-size: 2rem;
      color: $theme-color-base-ink;
      font-family: "Galaxie Copernicus";
      font-weight: normal;
      letter-spacing: -0.03em;
      line-height: 1.35;
    }
    h2 {
      font-size: 1.75rem;
      color: $theme-color-secondary;
      font-family: "Tesla Slab Bold 2";
      line-height: 1.15;
      &.number-h2 {
        font-size: 2rem;
        line-height: 1;
        color: $theme-color-base-ink;
      }
         &.text-ink{
                    color: #000000;
                  }
    }
    h3 {
      font-size: 1.063rem;
      color: $theme-color-secondary;
      font-family: "Galaxie Copernicus Bold";
      &.title-h3 {
        font-size: 1.375rem;
      }
      &.goal-text {
        color: $theme-color-base-ink;
      }
      &.stragegy-h3 {
        font-size: 1.375rem;
        font-family: "Galaxie Copernicus Medium";
        color: $theme-color-base-ink;
        line-height: 1.32;
        font-weight: 500;
        margin-top: 1em;
      }
      &.goal-h3 {
        color: $theme-color-base-ink;
        line-height: 135%;
        letter-spacing: -1.5%;
      }
    }
    h4 {
      font-size: 1rem;
      color: $theme-color-accent-cool-dark;
      font-family: "Galaxie Polaris";
      &.suc-tite {
        font-size: 0.75rem;
        line-height: 150%;
        color: $theme-color-accent-cool-dark;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    h5 {
      font-size: 0.75rem;
      color: $theme-color-base-ink;
      font-family: "Galaxie Polaris Medium";
    }
    h6 {
      font-size: 0.75rem;
      color: $theme-color-base-ink;
      font-family: "Galaxie Polaris";
    }
        .subtitle {
              font-size: 1.125em;
              line-height: 1.62;
            }
    p {
      font-size: 1rem;
      &.pri-name {
        font-size: 1.125rem;
        line-height: 135%;
      }
      &.pgov-body-md {
        font-size: 0.938rem;
      }
      &.pgov-ui-lg {
        font-size: 1.06rem;
      }
      &.pgov-ui-md {
        font-size: 0.93rem;
      }
      &.pma-date {
        font-size: 0.89rem;
        font-family: 'Tesla Slab Regular 2';
        padding: 0rem 1.2rem;
      }
      &.pri-hd {
        font-size: 0.75rem;
        font-family: "Galaxie Polaris Medium";
        line-height: 100%;
      }
      &.pma-footnote {
        font-size: 0.75rem;
        font-family: "Galaxie Polaris Medium";
        line-height: 162%;
        color: $theme-color-base;
      }
    }
          .usa-table thead th {
              background-color:$theme-color-base-ink;
              color: $theme-color-white;
          }
          .usa-table {
              font-family: "Galaxie Copernicus Medium";
              font-size: 0.875rem;
            }
    .usa-breadcrumb__list>li {
        font-family: 'Galaxie Copernicus Medium';
    }

    .usa-card__container {
    font-family: 'Galaxie Copernicus Medium';
    }
    .subtitle {
      font-size: 1.125em;
      line-height: 1.62;
    }
  }
  .usa-button {
    &.pma-footer-button {
      font-size: 14px;
      letter-spacing: unset;
    }
    &.milestone-bl{
      font-family: "Galaxie Polaris Medium";
    }
    &.incomplete-ms{
      font-family: "Galaxie Polaris Medium";
    }
  }
  .usa-media-block__body {
    max-width: revert;
  }
  .blog-tag-row{
    border-bottom: 1px solid $theme-color-base-lighter;
    &:last-child{
      border-bottom:0;
      margin-bottom: 3em;
    }
  }
  .usa-process-list{
        font-family: "Galaxie Copernicus Medium";
        .usa-process-list__item {
          border-left: 1px solid #0037fa;
          max-width: unset;
          &::before {
            content: '.';
            counter-increment: unset;
            background-color: #0037fa;
            border: 1px solid #0037fa;
            box-shadow: none;
            color: #0037fa;
            font-size: 1rem;
            left: 12px;
            height: 10px;
            width: 10px;
            margin-top: 0;
          }
        }
      }
  .info-icon{
    vertical-align: middle;
  }
  &.priority-pages{
    .strategy-card, .highlight-card{
      a:focus{
        outline: revert;
      }
    }
    .usa-modal__close{
      z-index: 2;
    }
    .usa-breadcrumb__list-item:not(:last-child)::after{
      bottom: -0.3em;
    }
  }
  .modal-img {
    display: inline-block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-right: 12px;
    background-image: url("../img/headshots/generic.jpeg");
  }
}
.togContainer {
  display: none;
}
.usa-button{
  border-radius: 0;
  font-family: "Galaxie Polaris";
  font-size: 0.875rem;
}

.usa-identifier {
     font-family: Arial, Helvetica, sans-serif;
     font-size: 1rem;
 }
.usa-identifier__required-link {
    font-size: 0.938rem;
    color: $theme-color-white;
}

.usa-identifier__identity-domain{
color: $theme-color-white;
}

.usa-identifier__identity-disclaimer a {
color: $theme-color-white;
}
.usa-identifier__section--usagov a {
color: $theme-color-white;
}
.usa-footer__return-to-top{
  font-family: "Galaxie Copernicus Medium";
}
.usa-banner__button:hover::after, .usa-banner__button[aria-expanded="true"]:hover::after{
  background-color: transparent;
}
.usa-header + main {
  padding-top:0.25em;
}

.circle-12 {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
}
.font-size-12{
  font-size:12px;
}
@media (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu {
    padding-left: 1em;
    padding-right: 1em;
    right: inherit;
    left: inherit;
  }
}
@media (max-width: 64em) {
  li.search-item {
    border: 1px solid $theme-color-base-lighter;
    margin: 2em 0 1em 0 ;
  }
}
.usa-modal {
 h1{
 font-family: "Galaxie Polaris";
 font-size: 1.25rem
 }
 h2{
 font-size: 1.063rem;
 }
 p{
     font-size: 0.875rem;
     &.history {
     }
       &.modal-breadcrumb{
       font-size: 0.75rem;
       }
    }
 li{
 font-size: 0.875rem;
 font-family: Arial, Helvetica, sans-serif;
 }
 .date{
    font-size: 0.875rem;
   }

    }

.bg-base-darkest {
    background-color: #1b1b1b;
}

p strong {
    font-family: 'Galaxie Copernicus Bold';
}

.usa-accordion__content p strong {
  font-family: Arial, Helvetica, sans-serif;
}
.usa-modal-wrapper.is-hidden{
  z-index: -1;
}
#twitter-widget-0{
  visibility: unset !important;
}

.Legend-colorBox {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: absolute;
    margin-top: 3px;

}

.legend-list li {
 text-align: center;
  @media screen and (max-width: $tablet-sm) {
     text-align: left;
   }
}


.pic-user {
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     background-color: white;;
 }

.usa-nav {
    font-family: "Tesla Slab Regular 2"

}


.usa-header--extended .usa-navbar {
    max-width: 90.28rem;
}

.usa-nav__secondary-links a {
    font-size: 1rem;
}

.usa-footer__contact-heading{
font-family: 'Tesla Slab Bold 2';
 font-size: 1.375em;
}

.usa-button--unstyled:visited {
    color: $theme-color-base-ink;
}