.pma-logo-img {
  width: 400px;
  height: 120px;
}

#pma-logo {
  position: relative;
  .pma-button {
    background-image: url(../img/usa-icons-bg/expand_more--white.svg);
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: calc(100% - 10px) center;
    padding-right: 2rem;
  }
}
.mini-nav {
  display: none;
  /*position: absolute;
  right: 0;
  z-index: 1000;*/

  .usa-link {
    font-family: "Tesla Slab Cy";
    background-image: none;
  }

  &:hover {
    /*display: block;
    width: 100%;*/
  }
}
.nav-margin {
  margin-bottom: 5.8rem;
}

.menu-font {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 103%;
  color: #000000;
  padding-left: 12px;
}

.pma-primary-item {
  color: #475467;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5; /* 150% */
  letter-spacing: 0.4px;
}

.usa-nav__primary > .pma-primary-item > a {
  color: #475467;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5; /* 150% */
}

.usa-nav__primary > .pma-primary-item > a:hover {
  color: #002578;
}

.pma-primary-item button {
  color: #475467;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5; /* 150% */
}

.pma-hero {
  width: 100%;
}

.hm-btn-style {
  font-family: Galaxie Polaris;
  font-style: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: -0.015em;
  background: $theme-color-primary;
  border-radius: 0;
  word-spacing: 1px;
}

.pma-banner-title {
  font-family: "Tesla Slab Bold 2";
  font-size: 50px;
  line-height: 100%;
  text-transform: uppercase;
  color: $theme-color-secondary;
  @media screen and (min-width: $iphone + 1) and (max-width: $tablet + 303) {
    font-size: 43px;
    padding-bottom: 21px;
  }
  @media screen and (max-width: $iphone) {
    font-size: 43px;
    padding-bottom: 21px;
  }
}

.pma-homepage-text {
  font-family: "Galaxie Copernicus Medium";
  font-size: 22px;
  line-height: 130%;
}

.pma-hd3 {
  font-family: Galaxie Polaris;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #565656;
  position: absolute;
  bottom: 15px;
  left: 25px;
}

.red-image-overlay-hm {
  height: 11px;
  background: #000000;
  transform: rotate(180deg);
  margin-top: -15px;
  z-index: 100;
  position: relative;
}

.home-header-txt {
  font-family: "Galaxie Copernicus";
  font-style: normal;
  font-size: 4.5rem;
  line-height: 103%;
  text-align: center;
  color: #000000;
}

.home-txt {
  font-family: "Galaxie Copernicus";
  font-style: normal;
  font-size: 1.375rem;
  line-height: 130%;
  text-align: center;
  color: $theme-color-primary;
}

.pma-text-container {
  text-align: center;
}

.pma-text-hd-container {
  text-align: center;
}

.home-red-hr {
  height: 13px;
  background: #002578;
  z-index: 100;
  position: relative;
}
.pma-text {
  font-family: "Tesla Slab Bold 2";
  font-size: 2rem;
  line-height: 95.5%;
  color: $theme-color-base-ink;
  padding-bottom: 1.5rem;
  @media screen and (min-width: $iphone + 1) and (max-width: $tablet) {
    padding-bottom: 0rem;
  }
  @media screen and (max-width: $iphone) {
    padding-bottom: 0rem;
  }
}

.pma-text2 {
  font-family: Galaxie Copernicus;
  font-size: 1.063;
  line-height: 120%;
  color: $theme-color-base-ink;
}

.pma-text3 {
  font-family: Galaxie Copernicus;
  font-size: 1.938rem;
  line-height: 129%;
  color: $theme-color-base-ink;
  @media only screen and (max-width: $desktop + 300) and (min-width: $tablet + 50) {
    font-size: 1.313rem;
  }
}

.ft1-img-container {
  height: 203px;
  background: url(../img/featured1.jpeg);
  background-size: cover;
}

.ft2-img-container {
  height: 203px;
  background: url(../img/featured2.jpeg);
  background-size: cover;
}

.ft3-img-container {
  height: 203px;
  background: url(../img/feature3.jpeg);
  background-size: cover;
}

.pma-usa-footer__logo-img {
  max-width: 6rem;
  width: 59px;
  height: 59px;
  left: 64px;
  top: 6038px;
}

.pma-footer {
  line-height: 95.5%;
}

.pma-footer-heading {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
}

.usa-social-link--mail-pma {
    background-image: url(../../img/social-icons/svg/mail.svg);
}
.usa-social-link {
  background-color: rgba($color: #fff, $alpha: 0.25);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  border-radius: 50%;
}
.usa-social-link--twitter-pma {
  background-image: url(../../img/social-icons/svg/twitter16.svg);
}
.usa-social-link--linkedin-pma {
  background-image: url(../../img/social-icons/svg/linkedin.svg);
}
.usa-link--external.usa-link--alt::after {
  background: url(../img/usa-icons-bg/launch--white.svg) no-repeat center /
    1.75ex 1.75ex;
}
.pma-footer a {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff !important;
  font-weight: normal;
}

.pma-footer-header {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-size: 1.25rem;
}

.whldp-header {
  font-family: "Tesla Slab Bold 2";
  font-size: 0.9rem;
  @media screen and (min-width: $desktop) {
    font-size: 1.458rem;
  }
}

.pma-footer a:focus {
  outline: 0.25rem solid #ffffff;
  outline-offset: 0;
}
.pma-footer a:hover {
  color: $theme-color-white;
}

.pma-list {
  line-height: 95.5%;
}

.pma-text-bt {
  font-family: "Tesla Slab Bold 2";
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 84%;
  text-transform: uppercase;
  color: $theme-color-secondary;
  @media screen and (min-width: $iphone + 1) and (max-width: $tablet + 370) {
    font-size: 50px;
  }
  @media screen and (max-width: $iphone) {
    font-size: 43px;
  }
}

.pma-text-bt1 {
  @extend .primary-font--medium;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: $theme-color-primary;
}

.pma-text-bt3 {
  @extend .primary-font--medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: $theme-color-base-ink;
}

.pma-footer-button {
  margin-right: 0;
  text-decoration: none !important;
  width: 192px;
  height: 34px;
  text-align: center;
}

.blue-image-overlay-hm {
  height: 110px;
  background: #4083ff;
  opacity: 1;
  margin-bottom: -110px;
  z-index: 100;
  position: relative;
  mix-blend-mode: multiply;
  margin-top: -7px;
  @media screen and (max-width: $tablet) {
    margin-top: -56px;
  }
}

.pma-home-title {
  font-family: " Tesla Slab Bold 2";
  font-size: 32px;
  line-height: 95.5%;
  color: #002578;
}

.card-image-bg {
  height: 355px;
}

.blog-highlight-style {
  font-family: "Galaxie Copernicus";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  color: $theme-color-base-ink;
}

.data-text {
  font-family: "Tesla Slab Bold 2";
  font-size: 22px;
  line-height: 95.5%;
  color: #002578;
}

.data-text-2 {
  font-family: Galaxie Copernicus;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.015em;
  color: $theme-color-base-ink;
}

.blue {
  color: $theme-color-primary;
}

.pma-card-image {
  height: 203px;
  width: 100%;
}

.pma-menu-btn {
  margin-top: -5rem;
}

.official-txt {
  font-family: Galaxie Polaris;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #002578;
  margin-top: 5.5rem;
}

.pma-sm-menu {
  font-family: "Tesla Slab Bold 2";
  font-size: 24px;
  line-height: 95.5%;
  color: $theme-color-primary !important;
}

.pma-logo-img-sm {
  width: 122.48px;
  height: 100px;
}

.pma-menu-btn {
  background-color: white;
  float: right;
  margin-top: -2.75rem;
}

.logo-title {
  font-family: "Tesla Slab Bold 2";
  font-size: 15px;
  line-height: 85%;
}

.logo-title-pma {
  font-family: "Tesla Slab Bold 2";
  font-size: 15px;
  line-height: 85%;
  text-decoration-line: underline;
  color: #0808ff;
  padding-left: 9px;
}

.usa-footer__primary-content {
  line-height: 0.8;
}

.hm-page-title-info {
  text-align: center;
  margin: 50px auto 35px auto;
  max-width: 863px;

  .hm-page-title {
    font-family: "Galaxie Copernicus";
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 103%;
    text-align: center;
    color: $theme-color-base-ink;
    margin-bottom: 0;
  }

  p {
    font-family: "Galaxie Copernicus Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 130%;
    text-align: center;
    color: $theme-color-primary;
    margin: 0;
    padding: 0;
  }
}

.priority-card {
  height: 56px;
  width: 56px;
  max-width: 100%;
  border-radius: 50%;
}

.priority-leaders-title {
  font-family: "Tesla Slab Regular 2";
  font-size: 15px;
  text-align: left;
  color: $theme-color-secondary;
  line-height: 135%;
}

.ag-header {
  font-family: Galaxie Copernicus;
  line-height: 115%;
}

.goal-title {
  font-family: Galaxie Polaris;
  line-height: 165%;
}

.usa-megamenu.usa-nav__submenu::after {
  background-color: transparent;
}

.usa-megamenu.usa-nav__submenu::before {
  background-color: transparent;
}
.usa-header [type="search"] {
  border: 0;
  margin-left: 0.5rem;
}
.usa-search .usa-button {
  font-size: 1.06rem;
}
.usa-footer {
  .usa-icon {
    vertical-align: middle;
    margin-left: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
  }
  .usa-link:visited {
    color: #fff;
  }
  .usa-link:hover {
    color: #fff;
  }
  .usa-button {
    width: 100%;
  }
}
/* ---------------- IPAD CSS ----------------------- */
@media (max-width: 768px) {
  .foter-tp {
    margin-top: 5rem;
  }
}
/* ---------------- MOBILE CSS ----------------------- */
@media screen and (max-width: 640px) {
  .pma-footer-button {
    margin-right: 0;
    text-decoration: none !important;
    width: 100%;
    height: 34px;
    text-align: center;
  }
  .content-desktop {
    display: none;
  }
  .foter-tp {
    margin-top: 5rem;
  }
  .brder-media {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
}

.tag-btn button {
  font-family: "Galaxie Copernicus";
  box-shadow: inset 0 0 0 1.3px #0808ff;
}

.tg-txt {
  font-family: "Tesla Slab Regular 2";
  color: $theme-color-base-ink;
}

.usa-nav .usa-search {
  margin-left: 0rem;
  margin-top: 0.5rem;
  border: 0;
  width: 20rem;
  @media screen and (min-width: 1378px) {
    width: 33rem;
  }
  @media screen and (min-width: 1254px) and (max-width: 1377px) {
    width: 28rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1110px) {
    width: 14rem;
  }
  .usa-input {
    max-width: 30rem;
  }
}
.usa-nav__secondary {
  bottom: 4.5rem;
}
.usa-header [role="search"] {
  max-width: calc(80ch + 3rem);
}

.progress-acc {
  background-color: #0808ff !important;
}

.init-milestone {
  font-size: 0.75rem;
  line-height: 150%;
  color: #1771ea;
  text-transform: uppercase;
  font-family: "Galaxie Polaris";
}

.progress-h3 {
  color: #000000;
  line-height: 135%;
  font-family: "Galaxie Copernicus Bold";
  font-size: 1.063rem;
}

.pma-txt {
  font-family: "Galaxie Copernicus Medium";
  font-size: 1.125rem;
  line-height: 2.25rem;
}

.usa-search--small [type="submit"],
.usa-search--small .usa-search__submit {
  background-image: url("../images/pgov-icons/search-blue.svg"),
    linear-gradient(transparent, transparent);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  width: 3rem;
}
