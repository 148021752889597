/* ------------------ Render crisp fonts ------------------ */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------ Borders */
.pgov-border-color-lt-blue {
  border-color: $theme-color-lt-blue;
}

.pgov-border-color-red {
  border-color: $theme-color-accent-warm;
}

.pgov-border-color-navy {
  border-color: $theme-color-secondary;
}

/* ------------------ Fills */
.pgov-fill-bright-blue {
    background-color: $theme-color-bright-blue;
}

.pgov-fill-red {
  background-color: $theme-color-accent-warm;
}

.pgov-fill-navy {
  background-color: $theme-color-secondary;
}

.pgov-fill-black {
  background-color: $theme-color-base-ink;
}

.pgov-fill-lt-blue {
  background-color: $theme-color-accent-cool-dark;
}

/* ------------------ Colors */
.pgov-color-red {
  color: $theme-color-accent-warm;
}

.pgov-color-blue {
  color: $theme-color-primary;
}


/* ------------------ Special */
.pgov-dropcap p:first-letter {
  font-family: "GP Thin Slab", serif;
  font-size: 5.5rem;
  /* 88px */
  padding-top: 0.245em;
  float: left;
  line-height: 0.6;
  margin-right: 0.3em;
  margin-bottom: 0.25em;
  color: $theme-color-primary;
  font-style: normal;
}

/* ------------------ Headers */
.pgov-h1 {
  font-family: "Galaxie Copernicus";
  font-size: 2rem;
  /* 32px */
  font-weight: 400;
  color: $theme-color-base-ink;
  letter-spacing: -0.03em;
}

.pgov-h2 {
  font-family: "Tesla Slab Bold 2", sans-serif;
  font-size: 1.75rem;
  /* 28px */
  font-weight: 700;
  color: $theme-color-secondary;
  line-height: 1.15;
}

.pgov-h3 {
  font-family: "Galaxie Copernicus Bold", sans-serif;
  font-size: 1.063rem;
  /* 17px */
  color: $theme-color-base-ink;
  line-height: 1.5;
  letter-spacing: -0.015em;
  &:hover {
     color: $theme-color-primary;
      }
}

.pgov-overline-1 {
  font-family: "Tesla Slab Bold 2", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: $theme-color-base-ink;
  line-height: 1.15;
  text-transform: uppercase;
}

.pgov-overline-2 {
  font-family: "Galaxie Polaris Medium", sans-serif;
  font-size: 12px;
  color: $theme-color-base-ink;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
}

/* ------------------ Body */
.pgov-body-sm {
  font-family: "Galaxie Copernicus Medium", serif;
  font-size: 0.875rem;
  /* 14px */
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 1.62;
}

.pgov-body {
  font-family: "Galaxie Copernicus Medium", serif;
  font-size: 1rem;
  font-weight: 500;
  color: $theme-color-base-ink;
  letter-spacing: -0.015em;
  line-height: 1.62;
}

.pgov-body strong {
  font-family: "Galaxie Copernicus Extra Bold", serif;
  color: $theme-color-base-ink;
  font-weight: 900;
}

.pgov-body-md {
  font-family: "Galaxie Copernicus Medium", serif;
  font-size: 0.938rem;
  /* 15px */
  font-weight: 500;
  color: $theme-color-base-ink;
  line-height: 1.62;
  letter-spacing: -0.015em;
}

/* ------------------ UI */
.pgov-ui-label {
  font-family: "Galaxie Polaris", sans-serif;
  font-size: 0.625rem;
  font-weight: 700;
  /* 10px */
  text-transform: uppercase;
  line-height: 1.35;
  color: $theme-color-base-ink;
  letter-spacing: 0.1em;
}

.pgov-ui-lg {
  font-family: "Tesla Slab Bold 2", sans-serif;
  font-weight: 700;
  color: $theme-color-secondary;
}

.pgov-ui-md {
  font-family: "Tesla Slab Regular 2", sans-serif;
  font-weight: 400;
  color: $theme-color-secondary;
}


/* ------------------- Utilities */

.pgov-serif {
  font-family: "Galaxie Copernicus Medium", serif;
}

/* ------------------ Buttons ------------------ */
.pgov-btn-sm {
  font-family: "Galaxie Polaris", sans-serif;
  font-size: 0.75rem;
  line-height: 2;
  /* 12px */
  font-weight: 700;
  color: $theme-color-secondary;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 0.188rem;
  /* 3px */
  border: none;
  background-color: $theme-color-white;
  border-bottom: solid 2px $theme-color-accent-warm;
  text-decoration: none;
}

.pgov-btn-sm:hover {
  color: $theme-color-primary;
  border-bottom: solid 2px $theme-color-primary;
}

#secondary-navigation a:not(.usa-button):hover {
    background-color: $theme-color-white;
}

/* ------------------ Document button */
.pgov-btn__doc {
  font-family: "Tesla Slab Bold 2", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: $theme-color-white;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.25rem 0.625rem;
  width: auto;
  display: inline-block;

}

.pgov-btn__doc-ag {
font-family: "Tesla Slab Bold 2", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: $theme-color-white;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.25rem 0.625rem;
  width: auto;
  display: inline-block;
  &:hover{
    color:#fff;
  }
  @media screen and (max-width: $tablet-sm - 1) {
      font-size: 1.2rem;
   }

}

.pgov-btn__doc-ag-2 {
  font-family: "Tesla Slab Bold 2", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    color: $theme-color-white;
    line-height: 1;
    text-transform: uppercase;
    border-radius: 0;
    padding: 0.25rem 0.625rem;
    width: auto;
    display: inline-block;
    &:hover{
      color:#fff;
    }
    @media screen and (max-width: $tablet-sm - 1) {
        font-size: 1.2rem;
     }
  
  }

.pgov-btn__doc {
  background: url(../images/pgov-icons/document.svg) no-repeat;
  float: right;
  margin-top: 3px;
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.pgov-btn__doc:hover {
  background-color: $theme-color-primary;
  color: #fff;
}

.pgov-metadata {
  color: $theme-color-primary;
  padding-bottom: 5px;
}

/* ------------------ Browse buttons */
.pgov-arrow .pgov-btn-arrow {
  width: 56px;
  height: 56px;
 border: 3px solid #000;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  cursor: pointer;
  background-color: $theme-color-white;
}

a:hover .pgov-arrow .pgov-btn-arrow {
  border-color: $theme-color-primary;
}

.pgov-btn-arrow span.arrow-left {
  background: url(../images/pgov-icons/lg-arrow-left_black.svg) no-repeat;
  float: left;
  margin-top: 2px;
  width: 39px;
  height: 24px;
  margin-left: 6px;
}

.pgov-btn-arrow span.arrow-right {
  background: url(../images/pgov-icons/lg-arrow-right_black.svg) no-repeat;
  float: right;
  margin-top: 2px;
  width: 39px;
  height: 24px;
  margin-right: 6px;
}

a:hover span.arrow-left {
  background: url(../images/pgov-icons/lg-arrow-left_blue.svg);
}

a:hover span.arrow-right {
  background: url(../images/pgov-icons/lg-arrow-right_blue.svg);
}


#pgov-browse-container {
  width:100%;
  text-align:center;
}

#pgov-browse-left {
  float:left;
  width: 39px;
  height: 24px;
}

#pgov-browse-center {
  display: inline-block;
  margin:1.25rem auto;
  width:auto;
  height: auto;
  line-height: 1;
  padding-left:0.3rem;
}

#pgov-browse-right {
  float:right;
  width: 39px;
  height: 24px;
}

#browse-label-desktop {
  line-height:1.8;
  margin-top:1.2rem;
}

.h1-margin{
margin: 0.67em 0;
}


/* ------------------ Profile header ------------------ */

.pgov-profile-header .usa-media-block__img {
  height:3.5rem;
  width:3.5rem;
  @media screen and (min-width: $tablet-sm - 1) {
    margin-right: 0.75rem;
  }
}

/* ------------------ Desktop layout */


.pgov-profile-header .usa-button-group {
  margin-bottom: .75rem;
}

.profile-title a {
  text-decoration: none;
}

.profile-title a:hover span {
  color: $theme-color-primary;
}


.profile-title a {
  margin-top: 0;
  padding-top: 0;
}


.small-agency {
    width: 46.83%;
    margin: 13px;
}

.agency-img {
    float: left;
    margin-right: 1rem;
}

/* ------------------ Hero image */
.pgov-hero__item-imgwrap {
  position: relative;
  --imgwidth: 100%;
  overflow: hidden;
  will-change: transform;
}

.pgov-hero__item-decobar-blue {
  width: 100%;
  height: 40px;
  top: 0%;
  position: absolute;
  background: $theme-color-lt-blue;
  mix-blend-mode: multiply;
}

.pgov-hero__item-decobar-red {
  width: 100%;
  height: 25px;
  bottom: 0%;
  position: absolute;
  background: $theme-color-accent-warm;
  mix-blend-mode: multiply;
}

/* ------------------ Center on mobile */

@media only screen and (max-width: 639px) {
  .pgov-profile-header .pgov-overline-1 {
    font-size: 2rem;
  }

  .pgov-profile-header .usa-media-block__img {
    height:50%;
    width:50%;
    max-width: 10rem;
  }

  .pgov-profile-header {
    text-align: center;
  }


}


@media only screen and (max-width: 639px) {
  .pgov-apg .pgov-overline-1 {
    font-size: 1.5rem;
  }

  .pgov-apg .usa-media-block__img {
    max-width: 3.5rem;
  }


}

/* ------------------ Strategic goals ------------------ */
/* ------------------ Eagle */
.pgov-eagle {
  max-width: 15rem; /* 240px */
}

/* ------------------ Strategic goal cards */
/* ------------------ Card */
.pgov-sg-card {
  border-radius: 0;
  padding-top: 1rem;
  padding-right: 2rem;
  border-top: 0.063rem solid $theme-color-primary;
  margin-bottom: 2rem;
}

.pgov-sg-card img:first-of-type {
  vertical-align:middle;
}

@media screen and (max-width: 640px) {
  .pgov-sg-card {
    padding-right: 0rem;
  }
}

.pgov-sg-card .usa-accordion__button {
  background-color: $theme-color-base-ink;
  color: $theme-color-white;
  font-family: "Galaxie Polaris", sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-bottom: none;
  padding: 0.75rem 1rem;
}

/* ------------------ Text styles for card */
.pgov-sg-card h2 {
  margin: 0;
  margin-top: 1rem;
}

.pgov-special-list ul {
  font-family: 'Tesla Slab Regular 2', sans-serif;
  padding-left: 0;
}

.pgov-sg-card h3 {
  font-family: 'Galaxie Copernicus Bold', serif;
  color: $theme-color-secondary;
  font-size: 1.375rem;
  letter-spacing: -0.04em;
  font-weight: 700;
  margin-top: 1rem;
  line-height: 1.5;
}

/* ------------------ Accordion */
.pgov-sg-card .usa-accordion__button {
  background-color: $theme-color-base-ink;
  color: $theme-color-white;
  font-family: 'Galaxie Polaris', sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-bottom: none;
  padding: 0.75rem 1rem;
}

.pgov-sg-card .usa-accordion__button:hover {
  background-color: $theme-color-primary;
}

.pgov-sg-card .usa-accordion__button[aria-expanded="false"] {
  background-image: url(../images/icons/add_white.svg), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.pgov-sg-card .usa-accordion__button[aria-expanded="true"] {
  background-image: url(../images/icons/remove_white.svg), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-color: $theme-color-primary;
}

/* ------------------ Goal leaders showcase ------------------ */
.pgov-user {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 12px;
  background-image: url('../images/pgov-headshot-icon.jpg');
}

.psrw-headshots {
  display: inline-block;
  width: 100%;
  height: 22rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 12px;
  background-image: url('../images/pgov-headshot-icon.jpg');
  @media screen and (max-width: $tablet + 1){
     height: 28rem;
    }
}

.pgov-goal-leaders {
  border-top: 0.5rem solid $theme-color-accent-warm;
  border-bottom: 5px solid $theme-color-secondary;
}

.pgov-goal-leaders-2 {
  border-bottom: 1px solid $theme-color-base-ink;
}

/* ------------------ Sidenav ------------------ */
.pgov .usa-sidenav {
  border-top: solid 0.5rem $theme-color-base-ink;
  margin-bottom: 2rem;
}

.pgov .usa-sidenav {
  border-top: solid 8px $theme-color-base-ink;
}

.pgov .usa-sidenav .usa-sidenav__item {
  border: 0;
  /* Remove existing borders */
}

.pgov .usa-sidenav a {
  border-left: 0;
  /* Remove existing borders */
  border-right: solid 1px $theme-color-lt-blue;
  border-bottom: solid 1px $theme-color-lt-blue;
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 0;
  padding-right: 8px;
  letter-spacing: 0px;
}

.pgov .usa-sidenav a .pgov-overline-2 {
  color: $theme-color-primary;
}

.pgov .usa-sidenav a:hover {
  background-color: $theme-color-white;
}

.pgov .usa-sidenav a img {
  display: none;
}

.pgov .usa-sidenav a:hover img {
  display: inline-block;
}

.usa-sidenav a:hover .pgov-h3 {
  color: $theme-color-primary;
  background-color: $theme-color-white;
}

/* ------------------ Tags ------------------ */
.pgov-page-tag {
  font-family: "Tesla Slab Bold 2", sans-serif;
  letter-spacing: 0.025rem;
  font-weight: 700;
  text-transform: uppercase;
}

.other-ag {
line-height:1;
margin-bottom:.5em;
margin-top:.5em;
}

.usa-prose > ul li {
margin-bottom: 0.75em;
}

.usa-sidenav .usa-current::after {
background-color: transparent;
}

@media screen and (min-width: 1100px) {
  .goal-menu .prio-item {
    width: 180px;
  }
  .goal-menu .prio-item .prio-container:after {
    bottom: 2px;
  }
  .goal-menu .prio-item .prio-container:after {
    content: " ";
    border-top: 11px solid #ec0d0d;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}

.goal-menu .prio-item {
  font-family: "Tesla Slab Regular 2", serif;
  position: relative;
  margin-bottom: 30px;
}

.goal-menu .prio-item .prio-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: fit-content;
  margin-top: auto;
  position: relative;
}

.goal-menu .prio-item .prio-container .prio-label {
  color: #ec0d0d;
  font-family: "Tesla Slab Bold 2";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 95.5%;
  position: absolute;
  top: 1.5em;
  left: 0;
  padding-bottom: 5px;
}

.goal-menu .prio-item .prio-container .prio-number {
  color: #0808ff;
  font-family: "GP Thin Slab";
  font-style: normal;
  font-weight: 800;
  font-size: 77px;
  line-height: 95.5%;
  padding-left: 1.25em;
  position: relative;
}

//pma
.border-goal {
  border-radius: 0;
  padding-top: 1rem;
  padding-right: 2rem;
  border-bottom: 0.063rem solid $theme-color-border;
  margin-bottom: 2rem;
}



.psrw-text {
color: $theme-color-white !important;
}

.pgov-fy-24-25 {
}
