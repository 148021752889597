.whldp-logo-img {
  max-width: 5rem;
}
  
.whldp-hero__overlay-content {
  min-height: 22rem;
}

.whldp-background {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url("https://assets.performance.gov/cx/files/whldp/2023/white-house-hero.png");
  object-fit: cover;
  background-color: #000;
  background-size: cover;
  background-position: top center;
}

.whldp-logo {
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-wrap: nowrap;

}


.whldp-logo-img {
  padding-top: 0.5rem;
}

@media (max-width: 63.99em){
  .whldp-logo-img {
    max-width: 2.75rem;
  }
}


.whldp-footer-logo {
  max-width: 5rem;
}

.whldp-media-block__body{
  max-width: 39rem;
  & li {
    margin-bottom: 1em;
  }
}


.fade_line {
  margin: 25px 0;
  height: 2px;
  background: black;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, #005ea2)); 
}

.circle {
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 50%;

}