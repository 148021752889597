// ||||||||||||||||||||||||||||||||||||||||||||||||||||| Define Global Variables


@import "packages/required";

$button-stroke: inset 0 0 0 units($theme-button-stroke-width);

// |||| ~~SIZES~~ |||

.pgov-usa-banner {
  font-family: var(--font-sans-1);
  line-height: var(--line-height-1);
  font-weight: var(--font-bold);
  letter-spacing: var(--letterspacing-3);
  font-size: var(--rem-10px);
  padding-top: var(--rem-8px);
  padding-bottom: var(--rem-8px);
}

.pgov-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-12);
  letter-spacing: var(--letterspacing-3);
}

.pgov-banner-text {
  font-family: var(--font-sans-1);
  line-height: var(--line-height-1);
  font-weight: var(--font-semibold);
  letter-spacing: var(--letterspacing-4);
  font-size: var(--rem-10px);
  color: var(--white);
  text-transform: uppercase;
  margin-top: var(--rem-8px);
  margin-bottom: var(--rem-8px);
}

.pgov-banner-icon {
  width: 16px;
  height: 11px;
  margin-right: var(--rem-8px);
}

:root {
  // Constrain width of content & headers via ch units.

  --size-content-1: 20ch;
  --size-content-2: 30ch;
  --size-content-3: 45ch;
  --size-content-4: 60ch;

  --size-header-1: 25ch;
  --size-header-2: 35ch;
  --size-header-3: 50ch;

  // Rem-to-PX syntax to make code ~designer-friendly~
  --rem-neg-5px: -0.313rem;
  --rem-neg-6px: -0.375rem;
  --rem-neg-2-decimal-64px: -0.625rem;
  --rem-1px: 0.0625em;
  --rem-2px: 0.125rem;
  --rem-3px: 0.1875rem;
  --rem-4px: 0.25rem;
  --rem-6px: 0.375rem;
  --rem-8px: 0.5rem;
  --rem-9px: 0.56rem;
  --rem-10px: 0.625rem;
  --rem-11px: 0.6875rem;
  // Smallest size for UI text
  --rem-12px: 0.75rem;
  --rem-13px: 0.8125rem;
  --rem-14px: 0.875rem;
  --rem-15px: 0.9375rem;
  // Largest size for UI text
  --rem-16px: 1rem;
  --rem-18px: 1.125rem;
  --rem-20px: 1.25rem;
  --rem-22px: 1.375rem;
  --rem-24px: 1.5rem;
  --rem-28px: 1.75rem;
  --rem-32px: 2rem;
  --rem-36px: 2.25rem;
  --rem-40px: 2.5rem;
  --rem-44px: 2.75rem;
  --rem-45px: 2.8125rem;
  --rem-46px: 2.875rem;
  --rem-48px: 3rem;
  --rem-56px: 3.5rem;
  --rem-64px: 4rem;
  --rem-72px: 4.5rem;
  --rem-80px: 5rem;
  --rem-88px: 5.5rem;
  --rem-120px: 7.5rem;
  --rem-160px: 10rem;
  --rem-240px: 15rem;
  --rem-256px: 16rem;
  --rem-272px: 17rem;
  --rem-280px: 17.5rem;
  --rem-320px: 20rem;
  --rem-340px: 21.25rem;
  --rem-364px: 22.75rem;
  --rem-372px: 23.25rem;
  --rem-380px: 23.75rem;
  --rem-400px: 25rem;
  --rem-440px: 27.5rem;
  --rem-456px: 28.5rem;
  --rem-480px: 30rem;
  --rem-500px: 31.25rem;
  --rem-708px: 44.25rem;

  // For responsiveness

    /* Small screens */
    --screen-xs: 0px;     /* Extra small screen / phone */
    --screen-sm: 480px;   /* Small screen / phone */
  
    /* Medium screens */
    --screen-md: 640px;   /* Medium screen / tablet */
    --screen-lg: 1024px;  /* Large screen / desktop */
  
    /* Large screens */
    --screen-xl: 1200px;  /* Extra large screen / wide desktop */
    --screen-2xl: 1400px; /* 2x large screen / wide desktop */

  // EM-to-PX syntax to make code ~designer-friendly~
  --em-neg-1-decimal-2px: -0.075em;
  --em-neg-1-decimal-0-24px: -0.064em;
  --em-neg-1-decimal-8px: -0.1125em;
  --em-neg-0-decimal-4px: -0.025em;
  --em-neg-0-decimal-6px: -0.038em;
  --em-neg-0-decimal-8px: -0.05em;
  --em-neg-0-decimal-32px: -0.02em;
  --em-neg-0-decimal-12px: -0.0075em;
  --em-0-decimal-75px: 0.075em;
  --em-0-decimal-64px: 0.04em;
  --em-0-decimal-40px: 0.025em;
  --em-0-decimal-32px: 0.02em;
  --em-0-decimal-16px: 0.01em;
  --em-1-decimal-2px: 0.075em;
  --em-1-decimal-64px: 0.1025em;
  --em-1px: 0.0625em;
  --em-2px: 0.125em;
  --em-3px: 0.1875em;
  --em-4px: 0.25em;
  --em-6px: 0.375em;
  --em-8px: 0.5em;
  --em-10px: 0.625em;
  --em-12px: 0.75em;
  --em-14px: 0.875em;
  --em-16px: 1em;
  --em-18px: 1.125em;
  --em-20px: 1.25em;
  --em-22px: 1.375em;
  --em-24px: 1.5em;
  --em-28px: 1.75em;
  --em-32px: 2em;
  --em-36px: 2.25em;
  --em-40px: 2.5em;
  --em-48px: 3em;
  --em-56px: 3.5em;
  --em-64px: 4em;
  --em-72px: 4.5em;
  --em-80px: 5em;
  --em-88px: 5.5em;
  --em-120px: 7.5em;
  --em-160px: 10em;
  --em-240px: 15em;
  --em-320px: 20em;
  --em-480px: 30em;

  // Sizes
  --size-1: var(--rem-4px);
  --size-2: var(--rem-8px);
  --size-3: var(--rem-16px);
  --size-4: var(--rem-20px);
  --size-5: var(--rem-24px);
  --size-6: var(--rem-28px);
  --size-7: var(--rem-32px);
  --size-8: var(--rem-48px);
  --size-9: var(--rem-64px);
  --size-10: var(--rem-80px);
  --size-11: var(--rem-120px);
  --size-12: var(--rem-160px);
  --size-13: var(--rem-240px);
  --size-14: var(--rem-320px);
  --size-15: var(--rem-480px);

  //|||| ~~FONT STUFF~~ |||

  // Font families
  --font-sans-1: "Arial", Arial, Helvetica, sans-serif;
  // USWDS open-source Brand font.
  --font-sans-2: "Galaxie Polaris", Arial, Helvetica, sans-serif;
  // Premium font by VLLG.
  --font-slab-serif-1: "Tesla Slab", ui-serif, serif;
  // Premium font by Typotheque.
  --font-slab-serif-2: "GPThin Slab", ui-serif, serif;
  // Custom font by Pentagram based on Polaris by VLLG.
  --font-serif: "Galaxie Copernicus", ui-serif, serif;
  // Premium font by VLLG.
  --font-serif-bold: "Galaxie Copernicus", ui-serif, serif;
  // Premium font by VLLG.
  --font-serif-extrabold: "Galaxie Copernicus", ui-serif, serif;
  // Premium font by VLLG.
  --font-condensed: "Galaxie Polaris Condensed", Arial, Helvetica, sans-serif;
  // Premium font by VLLG.

  // Font-weight-value-to-familiar-syntax to make code ~designer-friendly~
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 900;

  --font-size-0: var(--rem-11px);
  --font-size-1: var(--rem-12px);
  --font-size-2: var(--rem-13px);
  --font-size-3: var(--rem-14px);
  --font-size-4: var(--rem-16px);
  --font-size-5: var(--rem-20px);
  --font-size-6: var(--rem-22px);
  --font-size-7: var(--rem-32px);

  // Line height
  --line-height-neg-3: .5;
  --line-height-neg-2: 0.7;
  --line-height-neg-1: 0.95;
  --line-height-0: 1;
  --line-height-1: 1.25;
  --line-height-2: 1.35;
  --line-height-3: 1.5;
  --line-height-4: 1.72;
  --line-height-5: 2;
  --line-height-6: 3;
  --line-height-7: 4;
  --line-height-8: 4.25;

  // Letterspacing
  --letterspacing-neg-4: var(--em-neg-1-decimal-2px);
  // -1.2px.
  --letterspacing-neg-3: var(--em-neg-0-decimal-4px);
  // -.40px.
  --letterspacing-neg-2: var(--em-neg-0-decimal-32px);
  // -0.32px.
  --letterspacing-neg-1: var(--em-neg-0-decimal-12px);
  // -0.12px.
  --letterspacing-1: var(--em-0-decimal-16px);
  // 0.16px.
  --letterspacing-2: var(--em-0-decimal-32px);
  // 0.32px.
  --letterspacing-3: var(--em-0-decimal-64px);
  // 0.64px.
  --letterspacing-4: var(--em-1-decimal-2px);
  // 1.2px.
  --letterspacing-5: var(--em-1-decimal-64px);
  // 1.2px.

  // ||||||||||||||||||||||||||||||||||||||||||||||||||||| Primitive Colors

  // Constants
  --black: #000000;
  --white: #ffffff;
  --lightest-accessible-gray: #767676;
  // Pgov, lightest-accessible-gray.

  // Gray
  --gray-000: #fbfcfd;
  --gray-00: #f7f9fa;
  --gray-0: #f5f6f7;
  --gray-1: #edeff0;
  // USWDS,  gray-cool-10
  --gray-2: #dfe1e2;
  // USWDS, gray-cool-10
  --gray-3: #c6cace;
  // USWDS, gray-cool-20
  --gray-4: #a9aeb1;
  // USWDS, gray-cool-30
  --gray-5: #8d9297;
  // USWDS, gray-cool-40
  --gray-6: #71767a;
  // USWDS, gray-cool-50
  --gray-7: #565c65;
  // USWDS, gray-cool-60
  --gray-8: #3d4551;
  // USWDS, gray-cool-70
  --gray-9: #2d2e2f;
  // USWDS, gray-cool-80
  --gray-10: #1c1d1f;
  // USWDS, gray-cool-90
  --gray-11: #111117;
  --gray-12: #010101;

  // Navy
  --indigo-1: #002578;

  // Blue
  --blue-0: #adcdff;
  // USWDS, blue-warm-20v
  --blue-1: #4083ff;
  --blue-2: #2d63ff;
  --blue-4: #0037fa;

  // Red
  --red-1: #e52207;
  // USWDS, pgov-red
}

html {
  // Pgov text mostly comes in these shades of gray
  --gray-text-0: var(--lightest-accessible-gray);
  --gray-text-1: var(--gray-7);
  --gray-text-2: var(--gray-8);
  --gray-text-3: var(--gray-10);
  --gray-text-4: var(--gray-12);

  // Pgov text in navy
  --indigo-text-4: var(--indigo-1);
}

// ||||||||||||||||||||||||||||||||||||||||||||||||||||| Typography

// Make fonts render crisply

body.pma-page {
  font-family: var(--font-sans-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// Scoped to /pma/.. pages
.pma-page {
  // ||||||||||||||||||||||||||||||||||||||||||||||||||||| Color Preferences


  // Headers

  h1 {
    font-size: var(--font-size-7);
    font-family: var(--font-serif);
    font-weight: var(--font-normal);
    color: var(--gray-text-4);
    line-height: var(--lineheight-1);
    letter-spacing: var(--rem-neg-2-decimal-64px);
    max-inline-size: var(--size-header-3);
  }

  h2 {
    font-size: var(--font-size-7);
    font-family: var(--font-slab-serif-1);
    font-weight: var(--font-bold);
    color: var(--indigo-text-4);
    line-height: var(--lineheight-2);
    letter-spacing: var(--letterspacing-1);
    max-inline-size: var(--size-header-2);
  }

  h3 {
    font-size: var(--font-size-5);
    font-family: var(--font-serif);
    font-weight: var(--font-extrabold);
    color: var(--indigo-text-4);
    line-height: var(--line-height-2);
    letter-spacing: var(--letterspacing-neg-2);
    max-inline-size: var(--size-header-2);
  }

  h4 {
    font-size: var(--font-size-5);
    font-family: var(--font-serif);
    font-weight: var(--font-extrabold);
    color: var(--gray-11);
    line-height: var(--line-height-1);
    letter-spacing: var(--letterspacing-neg-3);
    max-inline-size: var(--size-header-3);
  }

  h5 {
    font-size: var(--font-size-3);
    font-family: var(--font-sans-1);
    font-weight: var(--font-bold);
    color: var(--gray-text-3);
    line-height: var(--line-height-1);
    letter-spacing: var(--letterspacing-1);
    max-inline-size: var(--size-header-3);
  }

  h6 {
    font-size: var(--font-size-2);
    font-family: var(--font-slab-serif-1);
    font-weight: var(--font-semibold);
    color: var(--gray-text-4);
    line-height: var(--line-height-3);
    letter-spacing: var(--letterspacing-4);
    max-inline-size: var(--size-header-2);
    text-transform: uppercase;
    margin-top: none;
    margin-bottom: var(--rem-4px);
  }

  .grid-row p {
    font-size: var(--font-size-3);
    font-family: var(--font-sans-1);
    font-weight: var(--font-normal);
    color: var(--gray-text-2);
    line-height: var(--line-height-4);
    
    max-inline-size: var(--size-content-4);
  }

  ul {
    font-size: var(--font-size-3);
    font-family: var(--font-sans-1);
    font-weight: var(--font-normal);
    color: var(--gray-text-2);
    line-height: var(--line-height-4);
    
    max-inline-size: var(--size-content-4);
  }

  .pgov-feed p {
    font-size: var(--rem-15px);
    font-family: var(--font-sans-1);
    font-weight: var(--font-normal);
    color: var(--gray-text-2);
    line-height: var(--line-height-4);
    
    max-inline-size: var(--size-content-4);
  }

  .pgov-feed-2 p,
  ul {
    font-size: var(--font-size-3);
    font-family: var(--font-sans-1);
    font-weight: var(--font-normal);
    color: var(--gray-text-2);
    line-height: var(--line-height-4);
    
    max-inline-size: var(--size-content-4);
  }

  .pgov-feed {

    .border-right,
    .border-left {
      border-color: var(--gray-1);
    }
  }

  small {
    font-size: var(--font-size-0);
    font-family: var(--font-sans-1);
    font-weight: var(--font-normal);
    color: var(--gray-text-2);
    line-height: var(--line-height-2);
    
    max-inline-size: var(--size-content-2);
  }

  .linkage a {
    font-size: var(--font-size-1);
    font-family: var(--font-sans-1);
    font-weight: var(--font-bold);
    color: var(--gray-text-2);
    line-height: var(--line-height-1);
    
    border-bottom: 1px solid var(--blue-0);
    padding-bottom: var(--rem-2px);

    // Disable default styling
    text-decoration: none;
  }

  .pgov-sidenav {
    max-width: var(--rem-280px);
  }

  .pgov-button-sm {
    font-size: var(--font-size-1);
    font-family: var(--font-sans-1);
    font-weight: var(--font-medium);
    color: var(--gray-text-4);
    line-height: var(--line-height-1);
    
    background-color: var(--gray-0);
    border-radius: var(--rem-500px);
    border: 1px solid var(--gray-0);
    padding-left: var(--em-12px);
    padding-right: var(--em-12px);
    padding-top: var(--em-8px);
    padding-bottom: var(--em-8px);
  }

  .pgov-button-sm:hover {
    color: var(--gray-text-4);
    background-color: var(--gray-1);
    border: 1px solid var(--gray-1);
  }

  .usa-button.pgov-button-md { 
    font-size: var(--font-size-1);
    font-family: var(--font-sans-1);
    font-weight: var(--font-semibold);
    color: var(--gray-text-4);
    line-height: var(--line-height-1);
    
    background-color: var(--white);
    border-radius: var(--rem-500px);
    border: 1px solid var(--gray-5);
    padding-left: var(--em-16px);
    padding-right: var(--em-16px);
    padding-top: var(--em-12px);
    padding-bottom: var(--em-12px);
  }

  .usa-button.pgov-button-md:hover {
    color: var(--gray-text-4);
    background-color: var(--white);
    border: 1px solid var(--gray-3);
  }

  .usa-button.pgov-button--dark {
    background-color: var(--gray-11);
    border: 1px solid var(--gray-11);
    color: var(--white);
  }

  .usa-button.pgov-button--dark:hover {
    background-color: var(--gray-10);
    border: 1px solid var(--gray-10);
    color: var(--white);
  }

  .usa-button.pgov-button-lg {
    font-size: var(--font-size-1);
    font-family: var(--font-sans-1);
    font-weight: var(--font-bold);
    color: var(--white);
    line-height: var(--line-height-1);
    
    background-color: var(--blue-4);
    border-radius: var(--rem-4px);
    text-transform: uppercase;
    padding-left: var(--em-18px);
    padding-right: var(--em-18px);
    padding-top: var(--em-14px);
    padding-bottom: var(--em-14px);
    border-radius: var(--em-3px);
  }

  .usa-button.pgov-button-lg:hover {
    background-color: var(--blue-2);
  }

  .usa-button.pgov-button-lg--dark {
    // Background color

    // Font size
    font-size: var(--font-size-1);
    // Font family
    font-family: var(--font-sans-1);
    // Font weight
    font-weight: var(--font-bold);
    // Color
    color: var(--white);
    // Lineheight
    line-height: var(--line-height-1);
    // Letterspacing
    
    // Background color
    background-color: var(--gray-11);
    // Border radius
    border-radius: var(--em-3px);
    // Text transform
    text-transform: uppercase;
    // Padding
    padding-left: var(--em-18px);
    padding-right: var(--em-18px);
    padding-top: var(--em-14px);
    padding-bottom: var(--em-14px);
  }

  .usa-button.pgov-button-lg:hover {
    // Background-color
    background-color: var(--blue-2);
  }

.usa-tab-content {
    display: none; /* Hide all tab content by default */
  }
  
.usa-tab-content[aria-hidden="false"] {
    display: block; /* Show only the active tab content */
  }

  .official .loading-text {
    position: absolute; top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    font-family: var(--font-slab-serif-1);
    letter-spacing: var(--letterspacing-neg-1);
    color: #0808ff; text-decoration:none;
    font-size: 2rem;
    font-style: italic;
  }

  // Icons

  span.pgov-icon-bolt-blue {
    background: url(https://assets.performance.gov/img/icons/pgov-bolt-blue.svg) no-repeat;
    float: left;
    width: var(--rem160px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-us-flag {
    background: url(https://assets.performance.gov/img/icons/flag.us.small.svg) no-repeat;
    width: 16px;
    height: 11px;
    margin-right: var(--rem-4px);
  }

  pgov-icon-us-flag span.pgov-icon-bolt-action {
    background: url(https://assets.performance.gov/img/icons/pgov-action.svg) no-repeat;
    float: right;
    width: var(--rem-12px);
    height: var(--rem-12px);
    margin-left: var(--rem-8px);
  }

  span.pgov-icon-bolt-red {
    background: url(https://assets.performance.gov/img/icons/pgov-bolt-red.svg) no-repeat;
    float: left;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-bolt-navy {
    background: url(https://assets.performance.gov/img/icons/pgov-bolt-navy.svg) no-repeat;
    float: left;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-linkage {
    background: url(https://assets.performance.gov/img/icons/pgov-linkage.svg) no-repeat;
    float: right;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-left: var(--rem-4px);
  }

  span.pgov-icon-linkage-2 {
    background: url(https://assets.performance.gov/img/icons/pgov-linkage.svg) no-repeat;
    float: left;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-chart-1 {
    background: url(https://assets.performance.gov/img/icons/pgov-bar-chart.svg) no-repeat;
    float: left;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-pulse {
    background: url(https://assets.performance.gov/img/icons/pgov-red-dot.svg) no-repeat;
    float: left;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-right: var(--rem-8px);
  }

  span.pgov-icon-guidance {
    background: url(https://assets.performance.gov/img/icons/pgov-bolt-blue.svg) no-repeat;
    float: right;
    width: var(--rem-16px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-p1-avatar-stack {
    background: url(https://assets.performance.gov/img/icons/pgov-pma-p1-leaders-stack.svg) no-repeat;
    float: left;
    width: var(--rem-40px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  span.pgov-icon-p3-avatar-stack {
    background: url(https://assets.performance.gov/img/icons/pgov-pma-p3-leaders-stack.svg) no-repeat;
    float: left;
    width: var(--rem-28px);
    height: var(--rem-16px);
    margin-right: var(--rem-4px);
  }

  .pgov-circle-number {
    border-radius: 50%;
    width: var(--em-24px);
    height: var(--em-24px);
    background: var(--gray-11);
    text-align: center;
    padding-top: var(--em-2px);
  }

  .pgov-circle-number a {
    font-size: var(--rem-12px);
    color: var(--white);
    font-family: var(--font-sans-1);
    line-height: var(--line-height-1);
    font-weight: var(--font-bold);
  }

  // USWDS Class Customizations

  .grid-container {
    max-width: 1400px;
  }

  .bg-base-lighter {
    background-color: var(--gray-1);
  }

  .bg-base-lightest {
    background-color: var(--gray-00);
  }

  .border-base-lighter {
    border-color: var(--gray-3);
  }

  .border-right,
  .border-left {
    border-color: var(--gray-1);
  }

  .pgov-topper {
    padding-top: var(--rem-48px);
    padding-bottom: var(--rem-16px);
    border-bottom: 1px solid var(--gray-2);
  }

  .pgov-section {
    padding-top: var(--rem-32px);
    padding-bottom: var(--rem-32px);
    margin-top: var(--rem-8px);
  }

  .usa-breadcrumb {
    background-color: none;
  }

  .breadcrumb .usa-button:visited {
    color: var(--gray-text-4);
  }

  .margin-top-0 {
    margin-top: none;
  }

  .pgov-topline {
    padding-top: var(--rem-32px);
    padding-bottom: var(--rem-32px);
    margin-top: none;
  }

  .bg-pgov-window {
    background-color: var(--gray-000);
  }

  .usa-breadcrumb {
    background-color: var(--gray-1);
    // Font size
    font-size: var(--font-size-0);
    // Font family
    font-family: var(--font-sans-1);
    // Font weight
    font-weight: var(--font-semibold);
    // Color
    color: var(--gray-text-2);
    // Lineheight
    line-height: var(--line-height-4);
    // Letterspacing
    letter-spacing: var(--letterspacing-3);
    // Uppercase
    text-transform: uppercase;
  }

  .usa-breadcrumb__link span {
    text-decoration: none;
  }

  .usa-breadcrumb__link span:hover {
    text-decoration: underline;
  }

  @media (min-width: 30em) {}

  .usa-breadcrumb {
    padding-bottom: 1rem;
    padding-top: 0rem;
  }

  .usa-breadcrumb__link:visited {
    color: var(--gray-text-2);
  }

  .usa-current {
    color: var(--gray-text-3);
    text-decoration: underline;
    font-weight: var(--font-bold);
    text-decoration-thickness: 3px;
  }

  .usa-breadcrumb__link {
    color: var(--gray-text-2);
  }

  // Customize modal

  .usa-modal--lg {
    max-width: 70rem;
    width: 100%;
    line-height: var(--line-height-2);
  }

  .usa-modal--lg .usa-modal__main {
    padding-top: 0rem;
    padding-bottom: 3rem;
    width: 100%;
    max-width: 68rem;
  }

  usa-modal__content {
    padding-top: var(--rem-40px);
  }

  .usa-modal h2 {
    color: var(--gray-11);
    margin-bottom: var(--rem-12px);
  }

  .usa-modal h3 {
    margin-bottom: var(--rem-12px);
  }

  .usa-modal__main .pgov-feed-2 {
    min-height: var(--rem-400px);
  }

  // Hide scrollbar for Chrome, Safari and Opera
  .pgov-feed::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  .pgov-feed {
    -ms-overflow-style: none;
    // IE and Edge
    scrollbar-width: none;
    // Firefox
  }

  // Hide scrollbar for Chrome, Safari and Opera
  .pgov-feed-2::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  .pgov-feed-2 {
    -ms-overflow-style: none;
    // IE and Edge
    scrollbar-width: none;
    // Firefox
  }

  // Breadcrumbs

  nav.breadcrumb {
    padding: 0.8em 1em;
    border: none;
    background: none;
    font-size: var(--rem-14px);
    color: var(--gray-text-2);
    font-family: var(--font-sans-1);
    font-weight: var(--font-semibold);
    
  }

  nav.breadcrumb a {
    color: var(--gray-text-2);
  }

  nav.breadcrumb a:hover {
    color: var(--gray-text-3);
  }

  nav.breadcrumb a:visited {
    text-decoration: none;
    color: var(--gray-text-2);
  }

  nav.breadcrumb ol {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  nav.breadcrumb li {
    display: inline;
  }

  nav.breadcrumb li+li::before {
    display: inline-block;
    margin: 0 0.5em;
    transform: rotate(15deg);
    border-right: 0.1em solid var(--gray-4);
    height: 0.8em;
    content: "";
  }

  nav.breadcrumb [aria-current="page"] {
    color: #000;
    font-weight: 700;
    text-decoration: none;
  }

  // Striped bars symbolizing transparency into American government

  .pgov-hero__item-imgwrap {
    position: relative;
    --imgwidth: 100%;
    overflow: hidden;
    will-change: transform;
  }

  .pgov-hero__item-decobar-lg-blue {
    width: 100%;
    height: var(--rem-40px);
    top: 0%;
    position: absolute;
    background: var(--blue-1);
    mix-blend-mode: multiply;
  }

  .pgov-hero__item-decobar-lg-red {
    width: 100%;
    height: var(--rem-24px);
    bottom: 0%;
    position: absolute;
    background: var(--red-1);
    mix-blend-mode: multiply;
  }

  .pgov-hero__item-decobar-md-blue {
    width: 100%;
    height: var(--rem-24px);
    top: 0%;
    position: absolute;
    background: var(--blue-1);
    mix-blend-mode: multiply;
  }

  .pgov-hero__item-decobar-md-red {
    width: 100%;
    height: var(--rem-12px);
    bottom: 0%;
    position: absolute;
    background: var(--red-1);
    mix-blend-mode: multiply;
  }

  // USWDS customization

  .height-16 {
    height: 7.5rem !important;
  }

  .pgov-font-sans-1-sm {
    font-size: var(--font-size-1) !important;
    
    font-family: var(--font-sans-1);
  }

  .pgov-ellipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid var(--blue-0);
    padding-bottom: var(--rem-2px);
    overflow: hidden;
    // Underline
  }

  .usa-sidenav {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    font-family: var(--font-sans-1);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    border-bottom: 1px solid rgb(173, 205, 255);
  }

  .usa-sidenav .usa-sidenav__item {
    background-color: var(--gray-0);
    margin-top: var(--rem-8px);
    border: none;
  }

  .usa-sidenav a:not(.usa-button) {
    padding: var(--rem-12px);
  }

  .usa-sidenav .usa-sidenav__item li:first-of-type {
    margin-top: none;
  }

  .usa-sidenav .usa-sidenav__item a {
    border: none;
    background-color: var(--gray-0);
    border: 1px solid var(--gray-0);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .usa-sidenav .usa-sidenav__item a:hover {
    border: 1px solid var(--gray-2);
    background-color: var(--white);
    color: var(--gray-11);
  }

  .pma-priority-font-label {
    font-family: var(--font-slab-serif-1);
    color: var(--indigo-text-4);
    letter-spacing: var(--line-height-1);
    line-height: var(--line-height-1);
    font-size: var(--rem-18px);
    font-weight: var(--font-bold);
    text-align: right;
  }

  .pma-priority-font-number {
    font-family: var(--font-slab-serif-2);
    color: var(--blue-4);
    letter-spacing: var(--line-height-1);
    line-height: var(--line-height-1);
    font-size: var(--rem-64px);
    font-weight: var(--font-extrabold);
    text-align: left;
  }

  .usa-button:visited {
    color: var(--gray-text);
  }

  .pgov-button-group .usa-button:visited {
    color: var(--white);
  }

  .official .usa-footer.usa-button--outline {
    box-shadow: inset 0 0 0 1px var(--gray-3);
    color: var(--white);
    background-color: transparent;
    transition: 0.3s;
}

.official .usa-footer.usa-button--outline:hover {
  color: var(--black);
}

  .display-on-desktop-lg {
    display: none;
  }

  @media only screen and (min-width: 1200px) {
    .display-on-desktop-lg {
      display: block;
    }
  }

  // Create avatar stack button

  .pgov-button-sm-people {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 50px;
    padding: 10px;
    text-decoration: none;
    color: white;
    background-color: blue;
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .avatar:not(:last-child) {
    margin-right: -10px;
  }

  ::-webkit-scrollbar {
    width: var(--rem-8px);
    height: 0px;
  }

  // Track
  ::-webkit-scrollbar-track {
    background: var(--gray-1);
  }

  // Handle
  ::-webkit-scrollbar-thumb {
    background: var(--gray-3);
  }

  // Handle on hover
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-4);
  }

  // Utility to center left-aligned text on mobile

  .text-left-to-center-mobile {
    text-align: left;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .modal-img {
    display: inline-block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .usa-button {
    width: auto;
  }
}

.indigo-text {
  color: var(--indigo-1);
}


.usa-button {
  @include set-text-and-bg("primary", $context: "Button");
}

.usa-button--secondary {
  @include set-text-and-bg("secondary", $context: "Button");

  &:hover,
  &.usa-button--hover {
    @include set-text-and-bg("secondary-dark", $context: "Button");
  }

  &:active,
  &.usa-button--active {
    @include set-text-and-bg("secondary-darker", $context: "Button");
  }
}

.usa-button--outline {
  background-color: color("transparent");
  box-shadow: $button-stroke color("primary");
  color: color("primary");

  &:visited {
    color: color("primary");
  }

  &:hover,
  &.usa-button--hover {
    background-color: color("transparent");
    box-shadow: $button-stroke color("primary-dark");
    color: color("primary-dark");
  }

  &:active,
  &.usa-button--active {
    background-color: color("transparent");
    box-shadow: $button-stroke color("primary-darker");
    color: color("primary-darker");
  }

  &.usa-button--inverse {
    $button-inverse-color: $theme-link-reverse-color;
    $button-inverse-hover-color: $theme-link-reverse-hover-color;
    $button-inverse-active-color: $theme-link-reverse-active-color;

    box-shadow: $button-stroke color("base-lighter");
    color: color($button-inverse-color);

    &:visited {
      color: color($button-inverse-color);
    }

    &:hover,
    &.usa-button--hover {
      box-shadow: $button-stroke color($button-inverse-hover-color);
      color: color($button-inverse-hover-color);
    }

    &:active,
    &.usa-button--active {
      background-color: transparent;
      box-shadow: $button-stroke color($button-inverse-active-color);
      color: color($button-inverse-active-color);
    }

    &.usa-button--unstyled {
      @include button-unstyled;
      color: color($button-inverse-color);

      &:visited {
        color: color($button-inverse-color);
      }

      &:hover,
      &.usa-button--hover {
        color: color($button-inverse-hover-color);
      }

      &:active,
      &.usa-button--active {
        color: color($button-inverse-active-color);
      }
    }
  }
}

.usa-breadcrumb__list-item:not(:last-child)::after {
  -webkit-mask: url(../img/usa-icons/navigate_next-pgov.svg) no-repeat center/2ex 2ex;
}

.usa-list--unstyled .usa-button--unstyled {
  background-color: white;
  color: black;
}

.usa-modal__close {
  background-color: transparent;
  color: black;
}

.bg-secondary,
.hover\:bg-secondary:hover {
  background-color: #002578;
}

.bg-primary-light,
.hover\:bg-primary-light:hover {
  background-color: #346bd1;
}

.text-primary {
  color: #0050d8;
}

.usa-tab-bar {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
}

.usa-tab {
  padding: 10px 20px;
  cursor: pointer;
}

.usa-tab[aria-selected="true"] {
  border-bottom: 2px solid #0071bc;
  font-weight: bold;
}

// Official Performance.gov styles and USWDS ovverrides are defined here.

body.official {
  font-family: var(--font-sans-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: var(--em-0-decimal-40px);
  line-height: var(--line-height-3);
}

/* Base padding (mobile first approach) */
.official .usa-section {
  padding-top: var(--rem-40px); /* Smaller padding for mobile */
  padding-bottom: var(--rem-40px);
}

/* Medium screens (tablets) */
@media (min-width: 1000px) {
  .official .usa-section {
    padding-top: var(--rem-64px); /* Increased padding for tablets */
    padding-bottom: var(--rem-64px);
  }
}

/* Large screens (desktops) */
@media (min-width: 1400px) {
  .official .usa-section {
    padding-top: var(--rem-88px); /* Original padding for desktops */
    padding-bottom: var(--rem-88px);
  }
}


/* Base  (mobile first approach) */
.text-left-switch {
  text-align: left;
}

/* Medium screens (tablets) */
@media (min-width: 640px) {
  .text-left-switch  {
    text-align: right;
  }
}

/* Base (mobile first approach) */
.text-center-switch-right {
  text-align: center;
}

/* Medium screens (tablets) */
@media (min-width: 640px) {
  .text-center-switch-right  {
    text-align: right;
  }
}

/* Base (mobile first approach) */
.text-left-switch-center {
  text-align: left;
}

/* Medium screens (tablets) */
@media (min-width: 640px) {
  .text-left-switch-center  {
    text-align: center;
  }
}

/* Base (mobile first approach) */
.text-center-switch-left {
  text-align: center;
}

/* Medium screens (tablets) */
@media (min-width: 640px) {
  .text-center-switch-left  {
    text-align: left;
  }
}

/* Base (mobile first approach) */
.border-none-switch-right {
  border-right: none;
}

/* Medium screens (tablets) */
@media (min-width: 640px) {
  .border-none-switch-right  {
    border-right: 1px solid;
  }
}

/* Base (mobile first approach) */
.display-none-up-to-1230px {
  display: none;
}

/* Medium screens (tablets) */
@media (min-width: 1230px) {
  .display-none-up-to-1230px {
    display: inline-block;
  }
}

/* Base (mobile first approach) */
.display-none-up-to-906px {
  display: none;
}

/* Medium screens (tablets) */
@media (min-width: 906px) {
  .display-none-up-to-906px {
    display: inline-block;
  }
}

.official .usa-section-2 {
  padding-top: var(--rem-88px);
  padding-bottom: none;
}

/* Grid */

.official .grid-container {
  max-width: 1216px;
}

/* Borders */

.border-lt-blue, .hover\:border-lt-blue:hover {
    border-color: var(--blue-0);
}

.border-lt-blue-soft {
  border-color: rgba(64, 131, 255, 0.25);
}

/* Headings */
/* Heading colors */
.official h1.usa-heading,
.official h3.usa-heading,
.official h6.usa-heading,
.h1-style {
  color: var(--gray-text-4); /* Replace with your desired color */
}

.official h2.usa-heading,
.official h4.usa-heading,
.official h5.usa-heading, {
  color: var(--indigo-text-4); /* Replace with your desired color */
}

.font-color-navy {
  color: var(--indigo-text-4); 
}

.font-color-navy {
  color: var(--indigo-text-4); 
}

.font-color-black {
  color: var(--black); 
}

a.pma-priority-panel-link {

  color: var(--gray-8);

}

.link {
  cursor: pointer;
  position: relative;
}


.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--red-1);
    top: 116%;
    left: 0;
    pointer-events: none;
}

.link::before {
    content: '';
    /* show by default */
}

.link--metis {
    font-family: bely-display, sans-serif;
}

.link--metis::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

.link--metis:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}

.link--carpo {
  font-family: var(--font-serif);
  font-weight: var(--font-semibold);
  color: var(--gray-text-4);
  font-style: italic;
  letter-spacing: var(--letterspacing-neg-2);
  box-shadow: 0 2px var(--red-1);
}

.link--carpo {
  font-family: var(--font-serif);
  font-weight: var(--font-semibold);
  color: var(--gray-text-4);
  font-style: italic;
  letter-spacing: var(--letterspacing-neg-2);
  box-shadow: 0 1px var(--blue-1);
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  padding-left: var(--rem-28px); /* Make space for the icon */
}

.link--carpo::before {
  content: '';
  position: absolute;
  left: 0; /* Align the icon to the left of the text */
  top: 36%;
  transform: translateY(-50%); /* Adjust the icon to be centered with the text */
  width: var(--rem-24px); /* Width of the icon */
  height: var(--rem-24px); /* Height of the icon */
  background-image: url('https://assets.performance.gov/pma/biden-harris/img/icons/bar_chart.svg'); /* Path to your icon */
  background-size: contain;
  background-repeat: no-repeat;
}

.clickable-panel {
  cursor: pointer; /* Makes the cursor appear as a pointer when hovering over the panel */
}

.clickable-panel:hover {
  background-color: rgba(173, 205, 255, 0.1);
}


p.pma-priority-panel-paragraph {

  color: var(--gray-8);

}

.font-color-red {
  color: var(--red-1); 
}

/* Heading - H1 */
.official h1.usa-heading, .h1-style {
  font-size: var(--rem-46px); /* Replace with your desired font size */
  font-family: var(--font-serif);
  font-weight: var(--font-light);
  line-height: var(--line-height-1);
  letter-spacing: -1.92px;
  margin: var(--em-16px) 0;
  margin-bottom: var(--rem-16px);
  margin-top: var(--rem-20px);
}

.height-mobile-lg {
  height: var(--rem-364px);
  width: auto;
}

.official .margin-block-end-0 h4  {
  margin-block-end: 0em;
}

.official p strong {
  font-weight: var(--font-bold);
  font-family: inherit;
  color: var(--gray-text-4)
}

/* Heading - H2 */
.official h2.usa-heading {
  font-size: var(--rem-40px); /* Replace with your desired font size */
  font-family: var(--font-slab-serif-1);
  font-weight: var(--font-bold);
  line-height: var(--line-height-0);
  letter-spacing: 0;
}

.pma-hero-container {
  position: relative;
  width: 544px;
  height: 654px;
  background-image: url('https://assets.performance.gov/cx/files/pma-homepage-hero.png'); background-size: cover; background-position: center;
  background-color: var(--gray-0);
}

@media (max-width: 640px) {
  .pma-hero-container {
    width: 100%; /* Make the width responsive */
    padding-top: (654 / 544) * 100%; /* Calculate aspect ratio and set as padding-top */  
  }

}



.official h1.h2-style, {
  color: var(--indigo-text-4); /* Replace with your desired color */
  font-size: var(--rem-40px); /* Replace with your desired font size */
  font-family: var(--font-slab-serif-1);
  font-weight: var(--font-bold);
  line-height: var(--line-height-0);
  letter-spacing: 0;
}

/* Heading - H3 */
.official h3.usa-heading {
  font-size: var(--rem-28px); /* Replace with your desired font size */
  font-family: var(--font-serif);
  font-weight: var(--font-light);
  line-height: 1.35;
  letter-spacing: var(--em-neg-0-decimal-6px);
}

/* Heading - H4 */
.official h4.usa-heading {
  font-size: var(--rem-20px); /* Replace with your desired font size */
  font-family: var(--font-serif);
  font-weight: var(--font-extrabold);
  line-height: var(--line-height-2);
  letter-spacing: var(--letterspacing-neg-1);
}

/* Heading - H5 */
.official h5.usa-heading {
  font-size: var(--rem-22px); /* Replace with your desired font size */
  font-family: var(--font-slab-serif-1);
  font-weight: var(--font-bold);
  line-height: var(--line-height-1);
  letter-spacing: var(--letterspacing-1);
}

/* Heading - H6 */
.official h6.usa-heading {
  font-size: var(--rem-14px); /* Replace with your desired font size */
  font-family: var(--font-sans-1);
  font-weight: var(--font-semibold);
  line-height: var(--line-height-1);
}

/* Heading - H6 */
.official p.author-name {
  font-size: var(--rem-14px); /* Replace with your desired font size */
  font-family: var(--font-sans-1);
  font-weight: var(--font-semibold);
  line-height: var(--line-height-1);
}

/* Text */

.official .usa-tag-2 {

    font-family: var(--font-sans-1);
    font-weight: var(--font-bold);
    font-size: var(--rem-10px);
    color: var(--gray-text-2);
    text-transform: uppercase;
    letter-spacing: var(--letterspacing-4);
    background-color: var(--white);
    border-radius: var(--rem-4px);
    margin-right: var(--em-10px);
    padding: var(--em-12px) var(--em-16px);
    border: 1px solid var(--gray-4);

}

.official .usa-tag-3 {

  font-family: var(--font-sans-1);
  font-weight: var(--font-bold);
  font-size: var(--rem-12px);
  color: var(--gray-text-2);
  text-transform: uppercase;
  letter-spacing: var(--letterspacing-4);
  background-color: var(--white);
  border-radius: var(--rem-240px);
  margin-right: var(--em-10px);
  padding: var(--em-12px) var(--em-16px);
  border: 1px solid var(--gray-4);

}

.official .usa-tag-2:hover {

  color: var(--indigo-1);
  border: 1px solid var(--blue-0);
  transition: 0.3s;
}

.official .usa-tag-red {

  font-family: var(--font-sans-1);
  font-weight: var(--font-bold);
  font-size: var(--rem-10px);
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: var(--letterspacing-4);
  background-color: var(--red-1);
  border-radius: var(--rem-160px);
  padding: var(--em-12px) var(--em-16px);
}

.official p.usa-prose,
.official span.usa-prose,
.official li.usa-prose,
.official ol.usa-prose {
  font-family: var(--font-sans-1);
  font-size: var(--rem-16px); /* Replace with your desired font size */
  font-weight: var(--font-normal);
  color: var(--gray-text-2);
}

.official .usa-in-page-nav__list a:not(.usa-button).usa-current {
  font-family: var(--font-serif);
  font-weight: var(--font-medium);
  font-size: var(--rem-14px);
  line-height: var(--line-height-4);

  letter-spacing: var(--letterspacing-neg-2);
}

.official .usa-in-page-nav__heading {
font-family: var(--font-slab-serif-1);
letter-spacing: var(--letterspacing-3);
text-transform: uppercase;
}

.official .usa-in-page-nav__link {
  font-style: italic;
}


.official .usa-in-page-nav__list a:not(.usa-button):not(.usa-current) {
  color: var(--indigo-1);
  font-family: var(--font-serif);
  font-size: var(--rem-14px);
  line-height: var(--line-height-3);
  font-weight: var(--font-medium);
  letter-spacing: var(--letterspacing-neg-2);
  line-height: var(--line-height-4);
}

.official p.usa-small-text {
  font-size: var(--rem-14px); /* Replace with your desired font size */
  font-weight: var(--font-medium);
  line-height: var(--em-20px);
  color: var(--gray-text-1);
}

.official .usa-checkbox__label::before {
  background-image: url(https://assets.performance.gov/cx/assets/images/life-experiences/pgov-left-quotemark.svg);
  box-shadow: 0 0 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 2rem;
}

.official .usa-prose, .usa-prose>table, .usa-prose>table caption, .usa-table caption {
  font-family: var(--font-sans-1);
}

.usa-tiny-text {
  font-size: var(--rem-12px); /* Replace with your desired font size */
  color: var(--gray-text-1);
  font-family: var(--font-sans-1);
}

/* ------------------ Special */
.pgov-dropcap p:first-letter {
  font-family: "GP Thin Slab", serif;
  font-size: 5.5rem;
  /* 88px */
  padding-top: 0.245em;
  float: left;
  line-height: 0.6;
  margin-right: 0.3em;
  margin-bottom: 0.25em;
  color: #000;
  font-style: normal;
}

.pma-mural .usa-tiny-text {
  font-size: var(--rem-12px); /* Replace with your desired font size */
  color: var(--gray-text-4);
  
  text-transform: uppercase;
}

span.light-backslash {
  font-weight: var(--font-semibold);
  color: var(--red-1);
}

.official span.usa-small-text {
  font-size: var(--rem-14px); /* Replace with your desired font size */
  font-weight: var(--font-medium);
  line-height: var(--em-20px);
  color: var(--gray-text-1);
}

.official .usa-label {
  font-family: var(--font-sans-1);
}

.official .usa-combo-box__input, .usa-input, .usa-input-group, .usa-select {
  border-width: 1px;
  border-color: var(--gray-3);
  border-radius: 4px;
  font-family: var(--font-sans-1);
  color: var(--lightest-accessible-gray);
}

.official .pgov-accordion ul li {
    font-family: var(--font-sans-1);
    font-size: 0.875rem;
}

.official .pgov-accordion .usa-accordion__button {
  font-family: var(--font-sans-1);
  text-transform: uppercase;
  font-size: var(--rem-12px);
  letter-spacing: var(--letterspacing-2);
}

.pgov-accordion p {
  font-family: var(--font-sans-1);
}

.official p.usa-lead {
  font-size: var(--rem-20px);
  color: var(--gray-text-1);
  line-height: var(--line-height-3);
}

.official p.subtitle {
  color: var(--indigo-1);
font-family: var(--font-serif);
font-size: var(--rem-20px);
font-style: italic;
font-weight: var(--font-medium);
line-height: 150%;
letter-spacing: var(--letterspacing-neg-1);
}

.official .usa-tab[aria-selected=true] {
  border-bottom: 4px solid var(--gray-text-4);
  font-weight: bold;
  margin-bottom: -1px;
}

.official .font-tesla {
  font-size: var(--rem-14px);
  font-family: var(--font-slab-serif-1);
  font-weight: var(--font-semibold);
  line-height: var(--line-height-1);
  letter-spacing: var(--letterspacing-5);
  color: var(--gray-text-4); /* Replace with your desired color */
  text-transform: uppercase;
}

.official .usa-footer .font-tesla {
color: var(--white);
}

.official .font-tesla-2 {
  font-size: var(--rem-15px);
  font-family: var(--font-slab-serif-1);
  font-weight: var(--font-semibold);
  line-height: var(--line-height-1);
  letter-spacing: var(--letterspacing-5);
  color: var(--gray-text-4); /* Replace with your desired color */
  text-transform: uppercase;
}

.h2-acronym {

  color: var(--gray-text-1);
font-family: var(--font-slab-serif-1);
font-size: 24px;
font-style: normal;
font-weight: var(--font-normal);
line-height: 44px;
letter-spacing: -0.48px;
}

.pma-mural {
  width: 572px;
  height: 688px;
  background-image:url('https://assets.performance.gov/site/img/cards/pma-jacket-2x.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--gray-text-2);
}

.link-panel {
  border: 4px solid var(--white);
  }
  
  

.link-panel:hover {
border-top: 4px solid var(--red-1);
border-right: 4px solid var(--red-1);
}

.pma-mural p.usa-prose {
  font-weight: var(--font-bold);
  color: var(--gray-text-4);
  font-size: var(--rem-14px);
}

.pma-mural span:first-of-type {
  font-weight: var(--font-normal);
  color: var(--gray-text-2);
}

.pma-mural span:last-of-type {
  font-weight: var(--font-normal);
  color: var(--gray-4);
}

.official span.title-small-text {
  color: var(--gray-8);
  font-family: var(--font-serif);
  font-size: var(--rem-24px);
  font-style: italic;
  font-weight: var(--font-medium);
  line-height: 150%;
  letter-spacing: var(--letterspacing-neg-1);
}

.official .button-text-lead {
  color: var(--indigo-text-4);
  font-family: var(--font-slab-serif-1);
  font-size: var(--rem-22px);
  font-style: var(--font-normal);
  font-weight: var(--font-bold);
  line-height: var(--em-24px);
  letter-spacing: normal;
  text-decoration: none
}

.official .button-text-subtitle {
  color: var(--gray-7);
  font-family: var(--font-serif);
  font-size: var(--rem-16px);
  font-style: italic;
  font-weight: var(--font-medium);
  line-height: var(--em-24px);
  letter-spacing: normal;
  text-decoration: none;
}

.official a:-webkit-any-link {
  text-decoration: none;
}

.official .button-pill-text-lead {
  color: var(--indigo-text-4);
  font-weight: var(--font-medium);
  line-height: var(--em-24px);
  color: var(--indigo-text-4);
font-size: var(--rem-17px);
font-weight: var(--font-bold);
line-height: 1;
letter-spacing: var(--em-0-decimal-40px);
}

/* Hero */

.official #hero h1.usa-heading {

}

/* Buttons */
.official .usa-button {
  font-family: var(--font-sans-1);
  border-radius: var(--em-6px);
  -webkit-border-radius: var(--em-6px);
  -moz-border-radius: var(--em-6px);
  -ms-border-radius: var(--em-6px);
  -o-border-radius: var(--em-6px);
  padding: var(--em-12px) var(--em-20px);
  font-size: var(--rem-16px);
  font-weight: var(--font-semibold);
  line-height: var(--em-28px);
  color: var(--white);
  background-color: var(--gray-12);
  letter-spacing: var(--em-0-decimal-40px);
  margin-right: 0;
  transition: 0.3s;
}

.official .usa-button--big {
  /* Replace with your desired font size */
  height: var(--em-88px);
  padding: var(--em-16px) var(--em-32px);
}

.official .usa-button:hover {
  /* Replace with your desired font size */
  background-color: var(--indigo-1);
}

.official .usa-button--outline:hover {
  background-color: var(--white);
}


.official .usa-button--small {
  background-color: var(--gray-1);
  color: var(--gray-text-4);
  padding: var(--em-8px) var(--em-12px);
  font-size: var(--rem-14px);
  line-height: var(--em-20px);
  font-weight: var(--font-medium);
  border-radius: var(--em-480px);
  -webkit-border-radius: var(--em-480px);
  -moz-border-radius: var(--em-480px);
  -ms-border-radius: var(--em-480px);
  -o-border-radius: var(--em-480px);
}

.official .usa-button--small-special {
  background-color: var(--gray-0);
  color: var(--gray-text-4);
  padding: var(--em-4px) var(--em-4px);
  font-size: var(--rem-14px);
  line-height: var(--em-20px);
  font-weight: var(--font-medium);
  border-radius: var(--em-480px);
  -webkit-border-radius: var(--em-480px);
  -moz-border-radius: var(--em-480px);
  -ms-border-radius: var(--em-480px);
  -o-border-radius: var(--em-480px);
  height: var(--rem-40px);
  transition: 0.3s;
}

.official .usa-button--small-special:hover {
  background-color: var(--gray-1);
}

/* Outline Buttons */
.official .usa-button--outline {
  box-shadow: inset 0 0 0 1px var(--gray-3);
  color: var(--gray-text-4); /* Replace with your desired color */
  background-color: transparent;
  transition: 0.3s;
}

.official .big-button-list {
max-width: var(--rem-456px);
}

.official .big-button-list .usa-button {
  width: 100%;
  margin-bottom: var(--rem-12px);
}

.official .usa-button--outline:hover,
.official .usa-button--outline:focus {
  color: var(--indigo-text-4); /* Replace with your desired color */
}

.official .usa-button--outline:hover {
  box-shadow: inset 0 0 0 1px var(--blue-0);
}

/* Outline Button Sizes */
.official .usa-button--outline.usa-button--big {

}

.official .usa-button--outline {

}

.official .usa-button--outline.usa-button--small {

}

.official .usa-button--outline.usa-button--smaller {

}

/* Utility */

.truncate-1-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;  /* Ensure it respects its parent container width */
}

.truncate-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;  /* Ensure it respects its parent container width */
}

.truncate-3-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;  /* Ensure it respects its parent container width */
}

.truncate-4-line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;  /* Ensure it respects its parent container width */
}

.official a .usa-prose {
}

.official .usa-icon-list {
  font-family: var(--font-serif-extrabold);
  font-weight: var(--font-extrabold);
  letter-spacing: var(--letterspacing-neg-1);
}

.official .usa-icon-list a {
  color: var(--indigo-1);
}

.official .usa-list {
font-family: var(--font-sans-1);
font-size: var(--rem-16px);
font-weight: var(--font-normal);
line-height: var(--line-height-3);
color: var(--gray-text-2);
}

.margin-top-neg-6px {
  margin-top: var(--rem-neg-6px);
}

.margin-top-neg-5px {
  margin-top: var(--rem-neg-5px);
}

.red-blue-solid-banding {
  border-top: var(--rem-20px) solid var(--indigo-1);
  border-bottom: var(--rem-14px) solid var(--red-1);
}

/* Authors */

.author img {
    filter: grayscale(100%); /* Converts the image to grayscale */
    transition: filter 0.3s, transform 0.3s; /* Animation for grayscale effect on hover */
    background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
    width: var(--rem-40px);
    height: var(--rem-40px);
    margin-right: var(--rem-8px);
  }
  
  .author:hover img {
    filter: grayscale(0%); /* Removes grayscale on hover */
  }

/* Cards */
.official .usa-card {
  border-color: #123456; /* Replace with your desired color */
}

.official .usa-card__header .usa-card__heading {
  color: #123456; /* Replace with your desired color */
}

.official .usa-card__body {
  color: #123456; /* Replace with your desired color */
}

.margin-x-3px {
  margin-right: 2.5px;
  margin-left: 2.5px;
}

.official .usa-checkbox__label, .usa-combo-box__input, .usa-combo-box__list, .usa-fieldset, .usa-hint, .usa-input, .usa-input-group, .usa-radio__label, .usa-range, .usa-select, .usa-textarea {
  font-family: var(--font-sans-1);
  font-size: 1.06rem;
  line-height: 1.3;
}


.official .usa-card__footer {

}

/* Image Containers */


.image-container-0 {
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
}

.image-container-0 img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: top; /* Aligns the top of the image to the top of the container */
  filter: grayscale(100%); /* Converts the image to grayscale */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1); /* Default scale */
}

.image-container-0:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.no-grayscale {
  filter: grayscale(0%);
}


.img-featured-lg {
  height: 400px;
  width: calc(100% + 2rem);
  background-color: var(--gray-1);
  border-top: 48px solid var(--indigo-1);
  border-bottom: 32px solid var(--red-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
}

.img-featured-lg img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center; /* Aligns the top of the image to the top of the container */
  filter: grayscale(100%); /* Converts the image to grayscale */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1); /* Default scale */
}

.img-featured-450px {
  height: 450px;
  width: calc(100% + 2rem);
  background-color: var(--gray-1);
}

.img-featured-450px img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center;
}

.img-featured-380px {
  height: 380px;
  width: calc(100% + 2rem);
  background-color: var(--gray-1);
}

.img-featured-380px img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center;
}



.img-featured-lg:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

@media (min-width: 640px) {
  .img-featured-lg {
    width: 708px;
  }
}


.img-featured-lg-position-top {
  height: 436px;
  width: calc(100% + 2rem);
  background-color: var(--gray-1);
  overflow: hidden; 
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

/* Inner Borders using pseudo-elements */
.img-featured-lg-position-top::before,
.img-featured-lg-position-top::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.img-featured-lg-position-top::before {
  top: 0;
  height: 48px; /* Height for the top border */
  background: var(--indigo-1);
}

.img-featured-lg-position-top::after {
  bottom: 0;
  height: 32px; /* Height for the bottom border */
  background: var(--red-1);
}

.img-featured-lg-position-top img {
  padding-top: 48px;
  padding-bottom: 32px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1);
  position: relative;
  z-index: 1;
}

.img-featured-lg-position-top:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

@media (min-width: 640px) {
  .img-featured-lg-position-top {
    width: 708px;
  }
}

.img-featured-lg-translucent {
  height: 436px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

/* Outer Bars with multiply blend mode */
.img-featured-lg-translucent::before,
.img-featured-lg-translucent::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  mix-blend-mode: multiply;
}

/* Inner screen effect */
.img-featured-lg-translucent::before::after,
.img-featured-lg-translucent::after::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: screen;
  background: inherit; /* Inherits the background color from the parent pseudo-element */
}

.img-featured-lg-translucent::before {
  top: 0;
  height: 48px;
  background: var(--blue-1);
}

.img-featured-lg-translucent::after {
  bottom: 0;
  height: 32px;
  background: var(--red-1);
}


.img-featured-lg-translucent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1);
  position: relative;
  z-index: 1;
}

.img-featured-lg-translucent:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

@media (min-width: 640px) {
  .img-featured-lg-translucent {
    width: 100%;
  }
}



.image-container-2 {
  width: 320px;
  height: 200px;
  overflow: hidden; /* This will hide any parts of the image that exceed the container dimensions */
}

.image-container-2 img {
  width: 100%; /* The image will scale based on the container's width */
  height: 100%; /* The image will scale based on the container's height */
  object-fit: cover; /* The image will cover the entire container, cropping if necessary */
}


.image-container-3 {
  height: 580px;
  width: auto;
  background-color: var(--gray-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
}

.image-container-3 img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: top; /* Aligns the top of the image to the top of the container */
}

.img-featured-sm {
  height: 216px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* This will clip the image when it scales */
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.img-featured-sm::before,
.img-featured-sm::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.img-featured-sm::before {
  top: 0;
  height: var(--rem-20px);
  background: var(--indigo-1);
}

.img-featured-sm::after {
  bottom: 0;
  height: var(--rem-14px);
  background: var(--red-1);
}


.img-featured-sm img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1);
  /* Positioned relative to enable z-index */
  position: relative;
  z-index: 1;
}

.img-featured-sm:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

@media (min-width: 640px) {
  .img-featured-sm {
    height: 216px;
  }
}


/* Styling for the image */
.img-featured-sm img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1);
}

/* Hover effect for the image */
.img-featured-sm:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* Responsive design */
@media (min-width: 640px) {
  .img-featured-sm {
    height: 216px;
  }
}












.img-featured-md {
  height: 240px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* This will clip the image when it scales */
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.img-featured-md::before,
.img-featured-md::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.img-featured-md::before {
  top: 0;
  height: var(--rem-20px);
  background: var(--indigo-1);
}

.img-featured-md::after {
  bottom: 0;
  height: var(--rem-14px);
  background: var(--red-1);
}

.img-featured-md img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1);
  /* Positioned relative to enable z-index */
  position: relative;
  z-index: 1;
}

.img-featured-md:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

@media (min-width: 640px) {
  .img-featured-md {
    height: 320px;
  }
}


/* Styling for the image */
.img-featured-md img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
  background: lightgray 0px -214px / 100% 265.5% no-repeat, #FFF;
  transform: scale(1);
}

/* Hover effect for the image */
.img-featured-md:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* Responsive design */
@media (min-width: 640px) {
  .img-featured-md {
    height: 320px;
  }
}

/* Large image in color */

.img-color-lg {
  height: 420px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
  border-top: var(--rem-20px) solid var(--indigo-1);
    border-bottom: var(--rem-14px) solid var(--red-1);
}

.img-color-lg img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center; /* Aligns the top of the image to the top of the container */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  transform: scale(1); /* Default scale */
}

.img-color-md:hover lg {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* Medium image in color */

.img-color-md {
  height: 320px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
  border-top: var(--rem-20px) solid var(--indigo-1);
    border-bottom: var(--rem-14px) solid var(--red-1);
}

.img-color-md img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center; /* Aligns the top of the image to the top of the container */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  transform: scale(1); /* Default scale */
}

.img-color-md:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}


/* Small image in color */

.img-color-sm {
  height: 240px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
  border-top: var(--rem-20px) solid var(--indigo-1);
    border-bottom: var(--rem-14px) solid var(--red-1);
}

.img-color-sm img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center; /* Aligns the top of the image to the top of the container */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  transform: scale(1); /* Default scale */
}

.img-color-sm:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}


/* X-small image in color */

.img-color-xs {
  height: 160px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
  border-top: var(--rem-20px) solid var(--indigo-1);
    border-bottom: var(--rem-14px) solid var(--red-1);
}

.img-color-xs img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center; /* Aligns the top of the image to the top of the container */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  transform: scale(1); /* Default scale */
}

.img-color-xs:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}


/* Small image in grayscale */

.img-grayscale-sm {
  height: 240px;
  width: 100%;
  background-color: var(--gray-1);
  overflow: hidden; /* Prevents the image from overflowing the container */
  position: relative; /* Helps in positioning children elements, if needed */
  z-index: 1; /* Ensure the image container is positioned above other elements if necessary */
  border-top: var(--rem-20px) solid var(--indigo-1);
    border-bottom: var(--rem-14px) solid var(--red-1);
}

.img-grayscale-sm img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales the image to cover the container without distorting aspect ratio */
  object-position: center; /* Aligns the top of the image to the top of the container */
  filter: grayscale(100%); /* Converts the image to grayscale */
  transition: filter 0.3s, transform 0.3s; /* Animation for grayscale and zoom effect on hover */
  transform: scale(1); /* Default scale */
}

.img-grayscale-sm:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* Interactions */

/* --- Horizontal scroll on mobile for a row of buttons */

.scrollable-row {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable-row::-webkit-scrollbar {
  display: none;
}

.scrollable-row > div {
  flex: 0 0 auto; /* This tells the divs to maintain their width and not to grow or shrink */
}

.scrollable-row .usa-button {
  width: auto; /* The width of the buttons, adjust as needed */
  min-width: 8rem; /* Minimum width of the buttons, adjust as needed */
}

@media (max-width: 640px) {
  .scrollable-row {
    gap: .5rem;
  }

  .scrollable-row > div:not(:last-child) {
    margin-right: .5rem; /* Adjust as needed */
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .scrollable-row {
    justify-content: space-between;
    gap: 0.75rem;
  }

  .scrollable-row > div:not(:last-child) {
    margin-right: 0.75rem; /* Adjust as needed */
  }
}

@media (min-width: 1024px) {
  .scrollable-row {
    justify-content: center;
  }
  
  .scrollable-row > div:not(:last-child) {
    margin-right: 0.75rem; /* Adjust as needed */
  }
}

/* Hide the hamburger menu on desktop screens */
@media (min-width: 992px) { /* Adjust the pixel value based on your breakpoint */
  #mobile-menu-button {
      display: none;
  }
}

/* Hide the PMA logo and button on mobile screens */
@media (max-width: 991px) { /* Adjust the pixel value based on your breakpoint */
  .pma-logo-button-desktop {
      display: none;
  }
}

.official .usa-menu-btn {
  background-color: var(--white);
  border-left: 1px solid var(--blue-0);
}

.official .usa-menu-btn:hover {
  background-color: var(--gray-1);
  transition: 0.3s;
}

.official .bg-base-darkest {
  background-color: var(--gray-11);
}

.pma-strategy .usa-card__container {
  border: 1px solid #DFE1E2;
  border-radius: var(--em-4px);
  max-width: var(--rem-708px);
}

.pma-strategy .usa-card__container h3 {
font-family: var(--font-sans-1);
font-weight: bold;
font-size: var(--rem-18px);
margin-top: none;
}

.pma-strategy .usa-card__container .usa-card__body p {
  font-family: var(--font-sans-1);
  font-weight: var(--gray-text-3);
  font-size: var(--rem-16px);
  line-height: var(--em-24px);

}

.official .sticky {
  position: -webkit-sticky; /* For Safari */
  position: fixed;
  top: 80; /* Adjust based on your header height or desired offset */
  z-index: 1; /* Ensure it's higher than the z-index of other elements */
}

.hide {
  display: none;
}

.usa-sidenav__sublist {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  font-size: 1rem;
}

.usa-sidenav__item {
  font-family: Arial, Helvetica, sans-serif;
}


.story-card {
    flex: 0 0 auto; /* Prevent cards from shrinking and ensure they maintain their set width */
}

.story-card h2 {
  font-family: var(--font-serif-bold);
  font-size: var(--rem-18px);
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;  /* Ensure it respects its parent container width */
  letter-spacing: var(--em-neg-0-decimal-12px);
}

.story-card p {
  font-family: var(--font-sans-1);
  font-size: var(--rem-14px);
  color: #ffffff;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.story-inner p {
  font-family: var(--font-sans-1);
  font-size: var(--rem-16px);
}

.story-card small {
  font-family: var(--font-sans-1);
  font-weight: 700;
  font-size: var(--rem-12px);
  color: #ffffff;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-carousel-container {
  display: flex; /* Display children inline */
  flex-wrap: nowrap; /* Prevents wrapping to the next line */
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  flex-direction: row;
}

.card-carousel-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.scroll-arrow-next {
  cursor: pointer; }

.scroll-arrow-back {
  cursor: pointer; 
}

.usa-card__container {
  font-family: Arial, Helvetica, sans-serif;
}