// Import the US Web Design System SASS.
.custom-callout {
  max-width: 29rem;
  padding-bottom: .5rem;
}
.nameTitle {
  font-weight: bold;
}
.hr-color {
  width: 100%;
  height: 2px;
  background-color: #e41d3d;
  border: none;
  margin-top: 1.6rem;
}
.pic-icon {
  vertical-align: text-bottom;
}
.pic-txt {
  padding-left: 1.2rem;
  display:block
}

.footer-algn {
  padding-top: 1rem;
}
.foter {
  margin-top: -0.4rem;
}
.foter-links {
  margin-top: -0.6rem;
}
.flter-algn {
  margin-left: -18rem;
}
@media (max-width: 768px) {
  .whldp-logo {
  display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
        flex: 1 1 0%;
        font-size: 0.93rem;
        line-height: 1.2;
        margin-left: 1rem;
  }
  .usa-banner__button-text {
    color: #ffffff;
  }
}
.white-background {
  background: $theme-color-white 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}
.text-center {
  text-align: center;
}
.text-no-underline {
  text-decoration: none;
}
.display-inline-block {
  display: inline-block;
}

.card-member{
  width: 30%;
  margin: 10px;
  font-size: 18px;
  line-height: 24px;
}
abbr {
  text-decoration: none !important;
}
.anchor-fill {
  text-decoration: none;
  display: block;
  height: 100%;
}
.no-style {
  text-decoration: none;
  color: black;
}
.picon {
  height: 60px;
}
.white-background .circle {
  width: 115px;
  margin: 0 auto;
  background: #edf5ff;
  border-radius: 50%;
  height: 115px;
  text-align: center;
}
.white-background .circle .icon {
  margin-top: 25px;
}
.usa-graphic-list .usa-graphic-list__heading {
  font-size: 1.4rem;
  font-weight: 300;
  color: #162e51;
}
.box-spacing {
  margin-top: -62px;
}
.flex-justify {
  justify-content: center;
}
.usa-graphic-list .usa-graphic-list__heading {
  color: #0050d8;
}
.usa-banner__button:hover {
  color: #ffffff;
}
footer .font-ui-md {
  color: #5f6468;
}
.pic-fade-line {
  margin: 25px 0;
  height: 2px;
  background: black;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, #e41d3d)); }

  .whldp-txt {
    line-height: 1.6rem;
    margin-top: 0.3rem;
  }
  .whldp-txt-footer {
    line-height: 1.4rem;
  }

.font-sans-lg {
  font-size: 1.4639705882rem; }

@media all and (min-width: 30em) {
  .mobile-lg\:font-sans-lg {
    font-size: 1.4639705882rem; } }
@media all and (min-width: 40em) {
  .tablet\:font-sans-lg {
    font-size: 1.4639705882rem; } }
@media all and (min-width: 64em) {
  .desktop\:font-sans-lg {
    font-size: 1.4639705882rem; } }

.font-sans-xl {
  font-size: 2.1294117647rem; }

@media all and (min-width: 30em) {
  .mobile-lg\:font-sans-xl {
    font-size: 2.1294117647rem; } }
@media all and (min-width: 40em) {
  .tablet\:font-sans-xl {
    font-size: 2.1294117647rem; } }
@media all and (min-width: 64em) {
  .desktop\:font-sans-xl {
    font-size: 2.1294117647rem; } }
.font-alt-xl {
      font-size: 1.9514824798rem; }

  @media all and (min-width: 30em) {
      .mobile-lg\:font-alt-xl {
        font-size: 1.9514824798rem; } }
  @media all and (min-width: 40em) {
      .tablet\:font-alt-xl {
        font-size: 1.9514824798rem; } }
  @media all and (min-width: 64em) {
      .desktop\:font-alt-xl {
        font-size: 1.9514824798rem; } }

.font-sans-xl {
    font-size: 2.1294117647rem; }

  @media all and (min-width: 30em) {
      .mobile-lg\:font-sans-xl {
        font-size: 2.1294117647rem; } }
  @media all and (min-width: 40em) {
      .tablet\:font-sans-xl {
        font-size: 2.1294117647rem; } }
  @media all and (min-width: 64em) {
      .desktop\:font-sans-xl {
        font-size: 2.1294117647rem; } }

/* ---------------- IPAD CSS ----------------------- */
@media (max-width: 768px) {
  .whldp-txt {
    font-size: 1.1rem;
    line-height: 1.2rem;
    margin-top: 0.3rem;
  }
  .card-member{
    width: 31%;
    margin: 5px;
  }
  .clearfix-lg {
    height: 23rem;
}
.banner{
  margin-left: 0px;
 }
.usa-overlay.is-visible {
  opacity: 0.2;
  visibility: visible;
 }
 .filters .filter__col, .project-item__filters .filter__col {
  margin-right: 1rem;
  width: 70%;
 }
 .flter-algn {
   margin-left: 0px;
 }
}

/* ---------------- MOBILE CSS ----------------------- */
@media screen and (max-width: 640px) {
  .usa-banner__button-text {
    position: absolute;
    left: 3em;
    right: auto;
    text-decoration: underline;
    }
    .usa-banner__button[aria-expanded="true"]:before {
      background-color: #323a45;
    }
    .usa-banner__button:hover::after {
      content: "";
      background-color: #1a4480;
    }
    .usa-banner__button::after {
      background: url(../img/usa-icons-bg/expand_more--white.svg) no-repeat center/1rem 1rem;
    }
    .usa-banner__button[aria-expanded="true"]::after {
      background: url(../img/usa-icons-bg/expand_less--white.svg) no-repeat center/1rem 1rem;
    }

    .whldp-txt {
      line-height: 1.1rem;
      margin-top: 0.3rem;
    }
    .whldp-logo-img {
      padding-top: 0px;
    }
    .card-member{
      width: 100%;
    }
    .filters .filter__col, .project-item__filters .filter__col {
      margin-right: 0px;
      width: 100%;
    }
    .foter-contact {
      margin-left: 20px;
      margin-top: -3px;
    }
}

.width-full {
  width:100%;
}

blockquote {
	color: #0071bc;
	font-size: 1.25em;
	font-style: italic;
}
.pgov{
  h1{
    font-family: 'Galaxie Copernicus Bold';
    font-size: 40px;
    color: #000;
  }
  h2{
    font-family: "Tesla Slab Bold 2";
    color: #EC0D0D;
    font-size: 32px;
  }
  h3{
    font-family: 'Galaxie Copernicus Extra Bold';
    font-size: 24px;
    color: #002578;
  }
  h4 {
    font-family: 'Galaxie Copernicus Bold';
    font-weight: bold;
    color: #000;
  }
  h5{
    font-family: 'Galaxie Copernicus Bold';
    font-size: 18px;
    color: #000;
  }
  p,.usa-prose{
    font-family: 'Galaxie Copernicus';
    color: #000;
  }
  a{
    font-family: 'Galaxie Copernicus';
  }
  strong {
    font-family: 'Galaxie Copernicus bold';
  }
  .heading{
    font-family: 'Galaxie Copernicus Bold';
  }
  .big-text{
    font-size: 1.2em;
  }
  hr{
    height:1px;
  }
  #left-side-menu-buttons {
    display: none;
  }
  .usa-sidenav {
    a {
      letter-spacing: -1px;
      font-family: 'Galaxie Copernicus Medium';
    }
  }
  @media screen and (max-width: 900px) and (min-width: 600px) {
    .grid-gap {
      flex-direction: row-reverse;
    }

    #main-content {
      width: min-content;
    }

    #side-nav {
      width: 202px;
      padding-top: 0;
    }
  }
  @media screen and (max-width: 475px){
    #left-side-menu-buttons{
      display: block;
      padding: 10px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      text-align: center;
    }
  }
}
