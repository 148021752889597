.primary-nav-1 {
  border-bottom: 12px solid #0808ff;
}

.primary-nav-2 {
  border-bottom: 12px solid #ec0d0d;
}

.primary-nav-3 {
  border-bottom: 12px solid #002578;
}
.usa-header--extended .usa-logo{
  max-width:100%;
}

//responsive

@media (max-width: 63.99em) {
  .pma-section {
    padding-bottom: 3rem;
    padding-top: 0rem;
  }

  .pma-hd3 {
    display: none;
  }
  .pma-logo-img {
    display: none;
  }
  .logo-title-pma {
    display: none;
  }

  .humans-of-public-service-wrapper,
  .humans-of-public-service-title {
    border-left: 0.5px solid white !important;
    border-right: 0.5px solid white !important;
  }
  .pma-accordion a:not(.usa-button):not(.usa-current) {
    color: black;
  }
  .pma-nav__submenu-item {
    border-top: 1px solid white;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: 110%;
  }
}


.usa-nav__primary button[aria-expanded=true] {
  background-color: #fff;
  color: #002578;
}


@media (min-width: 64em) {
  .pma-primary-item button[aria-expanded="false"] span::after {
    top: 1.32rem;
  }
  .pma-primary-item button[aria-expanded="true"] span::after {
    top: 1.32rem;
    background-color: #002578;
  }

  .usa-header--basic .usa-nav__link:hover::after {
    background-color: $theme-color-white;
    border-top: 4px solid $theme-color-red;
    border-bottom: 2px solid $theme-color-lt-blue;
    padding-top: 2px;
  }

  .pma-logo-img-sm {
    display: none;
  }

  .mobile-img {
    display: none;
  }

  .official-txt {
    display: none;
  }

  .pma-sm-menu {
    display: none !important;
  }

  .menu-font {
    display: none;
  }

  .pma-nav__submenu {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    background-color: #fff;
    border: 1px solid #adcdff;
    color: #3d4551;
    width: 15rem;
    padding: 1rem;
    position: absolute;
    z-index: 400;
    border-radius: 6px;
    font-weight: normal;
    width: 20rem;
    box-shadow: 0px 6px 12px rgba(173, 205, 255, 0.15);
  }
}

.blog-page-title-info a {
  color: #0808ff;
}

// small screen menu
@media (max-width: 63.99em) {

  .pma-nav {
    width: 100%;
  }
}

@media (min-width: 40em) {
  .pma-section {
    padding-bottom: 0rem;
    padding-top: 0rem;
  }
}

@media only screen and (min-width: 1024px) {
  .underline {
    border-bottom: 0px;
  }
}

@media only screen and (max-width: 1254px) {
  .pma-accordion > li {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 523px) {
  .hm-page-title-info .hm-page-title {
    font-size: 50px;
  }
}

@media (min-width: 64em) {
  .usa-header--basic .pma-primary-item:last-of-type .pma-nav__submenu {
    position: absolute;
    right: auto;
  }
  .pma-nav .usa-nav {
    padding: 20px 1.5rem 2.25rem 3.5rem;
  }

  .pma-nav-container {
    max-width: 87.28rem;
  }
  .pma-nav .usa-nav {
    display: inline-block;
  }

  .pma-nav .usa-navbar {
    width: 14%;
  }

  .pma-nav + .pma-section,
  .pma-nav + main {
    border-top: 0px solid #dfe1e2;
  }

  .pma-nav__submenu .pma-nav__submenu-item a {
    color: black;
    font-family: Arial;
    font-size: 15px;
    line-height: 1.35;
  }
  .pma-nav__submenu .pma-nav__submenu-item a:hover {
    color: #002578;;
    text-decoration: none;
    background-color:rgba(173, 205, 255, 0.1);
  }
}

@media (max-width: 63.99em) and (min-width: 40em) {
  .pma-accordion .usa-current::after {
    background-color: white;
  }

  .usa-nav__primary > .pma-primary-item > a {
    font-family: "Tesla Slab Bold 2";
    font-style: normal;
    font-size: 23.905px;
    line-height: 110%;
  }

  .pma-primary-item button {
    font-family: "Tesla Slab Bold 2";
    font-style: normal;
    font-size: 23.905px;
    line-height: 95.5%;
  }
}

@media (max-width: 63.99em) {
  .pma-accordion .usa-current::after {
    background-color: white;
  }

  .usa-nav__primary > .pma-primary-item > a {
    font-family: "Tesla Slab Bold 2";
    font-style: normal;
    font-size: 23.905px;
    line-height: 110%;
  }

}

@media (max-width: 63.99em) {
  .pma-accordion > li {
    margin-bottom: 10px;
  }
  .bl-img-overlay {
    background: #4083ff;
    width: 145px;
    height: 13px;
    margin-top: -12px;
  }
  .pma-accordion {
    margin-top: -0.5rem;
  }

  .dsktop-img {
    display: none;
  }
}


.usa-header--extended .usa-megamenu.usa-nav__submenu::after,
.usa-header--extended .usa-megamenu.usa-nav__submenu::before {
  background-color: #002578;
}