/** Blog Page **/

.blog-page-title-info {
  text-align: center;
  margin: 50px auto 35px auto;
  max-width: 450px;

  .blog-page-title {
    @extend .primary-font--medium;
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 103%;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
  }

  p {
    @extend .primary-font--medium;
    font-style: normal;

    font-size: 22px;
    line-height: 130%;
    text-align: center;
    color: #0808ff;
    margin: 0;
    padding: 0;
  }
}

.blog-page-title-info__landing {
  @extend .primary-font--regular;
  text-align: center;
  margin: 50px auto 35px auto;
  max-width: 450px;
  @media screen and (max-width: $tablet-sm) {
    max-width: 350px;
  }
  .blog-page-title__landing {
    @extend .primary-font--regular;
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 103%;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
    @media screen and (max-width: $desktop) {
      font-size: 48px;
    }
  }
  p {
    font-style: normal;
    font-size: 22px;
    line-height: 130%;
    text-align: center;
    color: #0808ff;
    margin: 0;
    padding: 0;
  }
}

.blog-page--view-more {
  @extend .primary-font--medium;
  font-style: normal;
  font-size: 22px;
  line-height: 130%;
  a {
    color: #0808ff;
  }
}

.blog-page-content-titles {
  min-height: 100px;
}

.blog-header-image {
  width: 100%;
  object-fit: cover;
}
.blog-inner{
  height:489px;
}


.blog-list {
  line-height: 1 !important;
}

.grid-container.usa-graphic-list__row.humans-of-public-service {
  padding-left: 0;
  padding-right: 0;
}

/* #humans-of-public-service-image-1 {
    padding-top: 30px;
} */

.humans-of-public-service-wrapper,
.humans-of-public-service-title {
  padding-right: 20px;
  padding-left: 20px;
  border-left: 0.5px solid #4083ff;
  border-right: 0.5px solid #4083ff;
  margin-bottom: 0;
}

.pma-margin-top {
  margin-top: -2.25rem;
}

.twitter-overlay {
  height: 26px;
  background: #002578;
  margin-top: -43px;
  z-index: 100;
  position: relative;
  mix-blend-mode: multiply;
  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.blog-teaser-column-title {
  color: #112e51;
  height: 55px;
  font-family: "Tesla Slab Bold 2";
  font-size: 32px;
  line-height: 95.5%;
  color: #000000;
}

.blog-teaser-article {
  margin-bottom: 1em;
  text-decoration: none !important;
  display: block;
}

.blue-image-overlay {
  height: 100px;
  background: #4083ff;
  opacity: 1;
  margin-bottom: -100px;
  z-index: 100;
  position: relative;
  mix-blend-mode: multiply;
}

.red-image-overlay {
  height: 40px;
  background: #ec0d0d;
  opacity: 1;
  margin-top: -43px;
  z-index: 100;
  position: relative;
  mix-blend-mode: multiply;
}

.featured-stories-tagline,
.featured-stories-date {
  padding: 0;
}

.featured-stories-tagline {
  @extend .primary-font--regular;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 110%;
  margin-top: 10px;
  color: #000000;
}

.featured-stories-date {
  @extend .primary-font--regular;
  font-style: normal;
  font-weight: normal;
  font-size: 1.063rem;
  line-height: 120%;
  margin-top: 15px;
  text-align: left;
  color: #000000;
}

.blog-teaser-date {
  text-align: right;
}

.top-left {
  position: absolute;
  top: -9px;
  left: 1px;
}

.blog-tag {
  margin-top: 9px !important;
  font-family: Galaxie Polaris;
  font-size: 1rem;
  margin-left: -1px;
  color: white;
  margin-bottom: 0;
  padding: 6px 15px;
  background: #4083ff;
}

.humans-of-public-service-name {
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: "Tesla Slab Regular 2";
  font-style: normal;
  font-size: 1.375rem;
  line-height: 95.5%;
  margin-top: 10px !important;
  color: #ec0d0d;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 35px;
  margin-bottom: 0;
}

.humans-of-public-service-agency {
  font-family: Galaxie Polaris;
  font-style: normal;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: #565656;
}

.goal-style {
  @extend .primary-font--medium;
  font-style: normal;
  font-size: 2.688rem;
  line-height: 120%;
}


.blog-post-content-title {
  margin: 0;
  padding: 0 20px 0 10px;
  border-right: 0.5px solid #4083ff;
  color: #002578;
  font-family: "Tesla Slab Bold 2";
  font-size: 22px;
  @media screen and (max-width: $tablet-sm - 1) {
    border-right: none;
  }
  h2 {
    @media screen and (max-width: $tablet-sm - 1) {
      display: flex;
      width: 100%;
    }
    margin: 0;
    line-height: 1;
    font-weight: bold !important;
    font-size: 22px !important;
  }
}

.blog-post-first-letter {
  font-family: "GP Thin Slab";
  font-weight: bolder;
  font-style: normal;
  color: #0808ff;
  font-size: 194px;
  padding: 55px 50px 35px 0px;
  float: left;
  line-height: 0.5;
  @media screen and (max-width: $desktop) {
    padding: 30px 20px 20px 0px;
    font-size: 100px;
  }
}

.blog-post-content {
  margin: 0;
  padding: 20px 80px 20px 110px;
  text-align: left;
  text-justify: inter-word;
  border-right: 0.5px solid #4083ff;
  @media screen and (max-width: $tablet-sm - 1) {
    border-right: none;
  }
  @media screen and (min-width: $tablet + 1) and (max-width: $desktop) {
    padding: 20px 40px 20px 80px;
  }
  @media screen and (min-width: $iphone + 1) and (max-width: $tablet) {
    padding: 10px 30px 10px 10px;
  }
  @media screen and (max-width: $iphone) {
    padding: 0 10px 10px 10px;
    text-align: start;
  }
  p,
  li {
    @extend .primary-font--medium;
    font-size: 18px;
    @media screen and (max-width: $iphone) {
      font-size: 16.5px;
      word-spacing: 0;
    }
    word-spacing: -2px;
    line-height: 26px;
  }
  li {
    word-spacing: 0px;
    text-align: left;
    padding-bottom: 10px;
  }
}

.blog-image,
.blog-featured-image {
  padding: 0;
  margin: 0;
  width: 100%;
}

.blog-featured-image {
  object-fit: cover;
  height: 100%;
}

.blog-post-title {
  @extend .primary-font--regular;
  font-style: normal;
  font-weight: normal;
  font-size: 56.9763px;
  line-height: 110%;
  color: #000000;
  @media screen and (min-width: $iphone + 1) and (max-width: $tablet) {
    font-size: 36px;
  }
  @media screen and (max-width: $iphone) {
    font-size: 28px;
  }
}

.blog-post-excerpt {
  margin: 0;
  margin-bottom: 25px;
  max-width: 30ex;
  @extend .primary-font--regular;
  line-height: 55px;
  text-align: left;
  color: #000000;
  font-size: 46px;
  @media screen and (min-width: $iphone + 1) and (max-width: $tablet) {
    font-size: 30px;
    max-width: 100%;
  }
  @media screen and (max-width: $iphone) {
    font-size: 22px;
    max-width: 100%;
  }
}

.blog-post-upper-line {
  @media screen and (min-width: 1007px) {
    width: 100%;
    height: 11px;
    background: #0808ff;
    mix-blend-mode: multiply;
  }
}
.blog-post-upper-line--mobile {
  @media screen and (max-width: 1006px) {
    width: 100%;
    height: 11px;
    background: #0808ff;
    mix-blend-mode: multiply;
  }
}

.blog-landing-upper-line--mobile {
  @media screen and (max-width: 624px) {
    width: 100%;
    height: 11px;
    background: #002578;
    mix-blend-mode: multiply;
  }
}

.blog-post-image-learn-more-line {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 24px;
  background: #0808ff;
  mix-blend-mode: multiply;
}

.blog-post-learn-more-title {
  font-family: "Tesla Slab Bold 2";
  font-weight: bold;
  font-size: 23.49px;
  text-align: left;
  color: #002578;
}

.blog-post-learn-more-content {
  @extend .primary-font--medium;
  font-size: 18px;
  text-align: left;
  max-width: 40ex;
}

.blog-post-lower-line,
.blog-post-last-line {
  height: 24px;
  background: #ec0d0d;
  mix-blend-mode: multiply;
  text-align: right;
}

.blog-sidebar-content {
  min-height: 200px;
}

.blog-sidebar-content__img {
  min-height: 150px;
  line-height: 0;
}

.pps-image-overlay {
  height: 40px;
  background: #0808ff;
  opacity: 1;
  margin-top: -43px;
  z-index: 100;
  position: relative;
  mix-blend-mode: multiply;
}

.blog-image-caption {
  text-align: right;
  @extend .primary-font--medium;
  font-size: 16.5px;
  color: #002578;
  p {
    max-width: 200px;
    float: right;
  }
}

.blog-image-caption__page-flow {
  @extend .blog-image-caption;
  p {
    max-width: 80%;
    float: right;
    margin-bottom: 4em;
  }
}

.blog-post-red-bolded {
  color: #ec0d0d;
  font-weight: bold;
}

.blog-post-blue-bolded {
  color: #0808ff;
  font-weight: bold;
}

.blog-post-lower-line {
  width: 91.5%;
  margin-left: 8.5%;
  @media screen and (max-width: $tablet) {
    width: 100%;
    margin-left: 0;
    margin-top: -25px;
  }
}

.blog-post-last-line {
  width: 100%;
}

.blog-post-authored-content {
  padding: 10px 0;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 25px;
  @media screen and (max-width: $tablet) {
    margin-bottom: 15px;
  }
  p {
    margin: 0;
    padding: 0;
    @extend .primary-font--medium;
    font-size: 22px;
    text-align: left;
    color: #0808ff;
    @media screen and (max-width: $iphone) {
      font-size: 17px;
    }
  }
}

#no-frame-border-1 {
  height: 725px;
  max-width: 500px;
  width: 100%;
}


//new blog

.rt-blog,
.rt-blog a {
  color: $theme-color-primary;
  font-family: "Galaxie Copernicus Medium";
}

.nwblog-post-upper-line{
    width: 100%;
    height: 0.375rem;
    background: #0808ff;
}

.nwblog-authored p{
font-family: "Galaxie Copernicus Medium";
font-size:1rem;
}

.nwblog-blue-image-overlay{
    height: 6.25rem;
    background: #4083ff;
    opacity: 1;
    margin-bottom: -100px;
    z-index: 100;
    position: relative;
    mix-blend-mode: multiply;
}

.newblog-red-image-overlay {
    height: 2.188rem;
    background: #ec0d0d;
    opacity: 1;
    margin-top: -43px;
    z-index: 100;
    position: relative;
    mix-blend-mode: multiply;
}

.social-icon {
    display: inline-block;
    width: 2.5rem;
    height: 2.563rem;
    background-size: cover;
    margin-right: 12px;
}

.nw-blog-content{
    font-family: "Galaxie Copernicus Medium";
}


.bl-title {
    font-family: 'Galaxie Copernicus Medium';
    font-size: 1.5rem;
    line-height: 2.063rem;
}

.related-card {
    border-radius: 0;
    border-width: 0.5px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    border-image: initial;
}

.usa-card__media--exdent .usa-card__img {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.tag-btn a{
font-family: 'Galaxie Copernicus';
box-shadow: inset 0 0 0 1.3px #0808ff;
}

.nw-blog-inner{
height: 23.938rem;
}

.social-float{
float: right;
@media screen and (max-width: $iphone) {
      float: left;
    }
}

.strategy-float{
float: right;
@media screen and (max-width: $tablet + 100) {
      float: left;
    }
}

.nwblog-title {
    color: #002578;
    font-family: "Tesla Slab Bold 2";
    font-size: 1.375rem;
}

.nwblog-center-img {
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.usa-summary-box {
    font-family: "Galaxie Copernicus Medium";
}