.paginate-link {
  display: none;
  @include at-media('tablet') {
    display: inline-block;
  }
}
.paginate-button {
  display: block;
  @include at-media('tablet') {
    display: none;
  }
}
