// breakpoints

$iphone: 510px;
$tablet: 820px;
$tablet-sm: 640px;
$desktop: 1100px;
$theme-color-base-ink: #000000;
$theme-color-base-lightest: #f0f0f0;
$theme-color-base-lighter: #dcdee0;
$theme-color-base-light: #a9aeb1;
$theme-color-base: #71767a;
$theme-color-base-dark: #565c65;
$theme-color-base-darker: "gray-cool-70";
$theme-color-base-darkest: #3d4551;
$theme-color-primary: #0808ff;
$theme-color-secondary: #002578;
$theme-color-accent-cool: #00bde3;
$theme-color-accent-cool-dark: #1771ea;
$theme-color-accent-cool-light: #97d4ea;
$theme-color-accent-warm: #ec0d0d;
$theme-color-border: #c4c4c4;
$theme-root-font-size: 16px;
$theme-show-notifications: false;
$theme-link-color: "secondary";
$theme-color-accent-cool-light: #97d4ea;
$theme-color-lt-blue: #4083ff;
$theme-color-white: #fff;
$theme-color-red: #ec0d0d;
$theme-color-accessible-gray: #767676;
$theme-body-line-height: 6;
$theme-color-bright-blue: #0808ff;
